/**
* @Author: Ajay
* @Date:   20-Mar-2017 12:11 +05:30
* @Project: Ezyprocure
* @Filename: buyer.price-update-history.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-24T12:38:04+05:30
*/




(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('BuyerPriceUpdateHistoryController', BuyerPriceUpdateHistoryController);

  BuyerPriceUpdateHistoryController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'pagingParams', 'BuyerDataService', 'NotificationService', 'UTCDateService', 'PriceUpdateHistoryService', 'BuyerSupplierMappingService', 'ItemGroupService', 'paginationConstants', 'BuyerBuyNowService', 'CSVExportService', 'EXPORT_CONSTANTS', 'GLOBAL_CONFIG_CONSTANTS', 'GLOBAL_CONSTANTS', 'Auth', 'BuyerBusinessService','CacheMiddlewareService'];

  function BuyerPriceUpdateHistoryController($scope, $rootScope, $q, $filter, $state, $stateParams, toastr, $mdDialog, pagingParams, BuyerDataService, NotificationService, UTCDateService, PriceUpdateHistoryService, BuyerSupplierMappingService, ItemGroupService, paginationConstants, BuyerBuyNowService, CSVExportService, EXPORT_CONSTANTS, GLOBAL_CONFIG_CONSTANTS, GLOBAL_CONSTANTS, Auth, BuyerBusinessService,CacheMiddlewareService) {
    var vm = this;
    vm.init = init;
    vm.predicate = JSON.parse(localStorage.getItem("price-update-history-predicate")) || pagingParams.predicate;
    vm.reverse = JSON.parse(localStorage.getItem("price-update-history-reverse")) || pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.loadItemGroups = loadItemGroups;
    vm.loadAll = loadAll;
    vm.supplier = '';
    vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
    vm.filter = JSON.parse(localStorage.getItem("price-update-history-filter")) || {
      businessIdList: [],
      fromDate : vm.todaysDate,
      toDate : vm.todaysDate
    };
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;

    vm.priceUpdateStatusClass = GLOBAL_CONFIG_CONSTANTS.PRICE_UPDATE_STATUS.LABEL_CLASS;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');


    vm.query = JSON.parse(localStorage.getItem("price-update-history-paginate")) || {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };

    vm.limitOptions = paginationConstants.limitOptions;
    //Header
    //Function Declaration
    vm.getPriceUpdateHistoryCSVData = getPriceUpdateHistoryCSVData;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    //filter reset
    vm.resetFilters = resetFilters;

    function init() {
      if(vm.filter.businessIdList) {
        vm.getAllBuyersBusinessOutlets();
      }
      if(vm.filter.supplierId) {
        vm.getAllAllocatedSuppliersForBusiness();
      }
      if(vm.filter.itemGroupId) {
        vm.loadItemGroups();
      }
      if (vm.accessForPriceDisplay)
        vm.priceUpdateReportHeader = EXPORT_CONSTANTS.CSV.BuyerPriceUpdateReport;
      else {
        vm.priceUpdateReportHeader = _.without(EXPORT_CONSTANTS.CSV.BuyerPriceUpdateReport, 'Previous Price', 'Updated Price', 'Currency');
      }
      vm.loadAll();
    }

    //get all business outlets of the selected buyer
    function getAllBuyersBusinessOutlets() {
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        groupBy: 'buyer'
      };
      BuyerBusinessService.getAllBuyersBusinessOutlets([BuyerDataService.getBuyerProfile().buyerUser.buyer.id], params).then(function (response) {
        vm.bussinessOutlets = response.data;
      }, function (error) {
      });
    }

    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BUSINESSOUTLETS':
          vm.filter.businessIdList = _.map(vm.bussinessOutlets[0].businessDTOList, _.property('businessId'));
          break;
      }
    }

    function deselectAll(dropdown){
      switch(dropdown){
        case 'BUSINESSOUTLETS':
          vm.filter.businessIdList = [];
          break;
      }
    }

    //Getting CSV DATA
    function getPriceUpdateHistoryCSVData() {
      var deferred = $q.defer();
      if (!vm.priceUpdateItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getBuyerPriceUpdateHistoryReportCSV(vm.priceUpdateItems);
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
    */
    function getAllAllocatedSuppliersForBusiness() {
      if (vm.supplierList)
        return false;
      var params = { 'businessId': !vm.businessOutletId ? '' : vm.businessOutletId };
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function (response) {
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
        //if only one allocated supplier set it as default
        if (vm.supplierList.length === 1) {
          vm.filter.supplierId = vm.supplierList[0].id;
        }
      }, function (error) {
      });
    }

    function loadItemGroups() {
      var endpoint = '/api/item-groups';
      var param={page: 0,size: paginationConstants.maxLimit,query: ''};
      if (vm.itemGroups)
          return false;
      vm.itemGroups = null;
      CacheMiddlewareService.getData(endpoint,ItemGroupService.getAllItemGroupsData,param).
          then(function (response) {
            vm.itemGroups = response;
          }, function (error) {
          console.error('Error fetching item Groups:', error);
          });
  }

    /**
    * [loadAll loads all price update history]
    */
    function loadAll() {
        localStorage.setItem("price-update-history-filter", JSON.stringify(vm.filter));
        localStorage.setItem("price-update-history-predicate", JSON.stringify(vm.predicate));
        localStorage.setItem("price-update-history-reverse", JSON.stringify(vm.reverse));
        localStorage.setItem("price-update-history-paginate", JSON.stringify(vm.query));
      PriceUpdateHistoryService.getAllPriceUpdatesHistory({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.fromDate)),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.toDate)),
        supplierId: !vm.filter.supplierId ? '' : vm.filter.supplierId,
        itemGroupId: !vm.filter.itemGroupId ? '' : vm.filter.itemGroupId,
        businessIdList :  vm.filter.businessIdList.length != 0 ?  vm.filter.businessIdList : '',
        query: '',
        itemName: vm.filter.itemName ? vm.filter.itemName : '',
        itemCode: vm.filter.itemCode ? vm.filter.itemCode : ''
      }).then(function (response) {
        vm.priceUpdateItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function (error) {
        NotificationService.error({
          title: 'Price Update History Details',
          error: error
        });
      });
    }
    // filter reset
    function resetFilters() {
        vm.filter.fromDate = vm.todaysDate;
        vm.filter.toDate = vm.todaysDate;
        vm.filter.supplierId = '';
        vm.filter.businessIdList = '';
        vm.filter.itemGroupId = '';
        vm.filter.itemCode = '';
        vm.filter.itemName = '';
        vm.priceUpdateItems = [];
        localStorage.setItem("price-update-history-filter", JSON.stringify(vm.filter));
    }
  }
})();
