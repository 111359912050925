/**
* @Author: hareesh
* @Date:   13-Aug-2017 09:11 +05:30
* @Project: Ezyprocure
* @Filename: supplier.top-buyers.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:41 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierTopPurchasedController', SupplierTopPurchasedController);

  SupplierTopPurchasedController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', 'pagingParams', '$stateParams', 'toastr', '$mdDialog', 'UTCDateService','SupplierPriceUpdateHistoryService', 'ItemGroupService', 'paginationConstants', 'CSVExportService', 'EXPORT_CONSTANTS', 'NotificationService','CacheMiddlewareService'];

  function SupplierTopPurchasedController($scope, $rootScope, $q, $filter, $state, pagingParams, $stateParams, toastr, $mdDialog, UTCDateService, SupplierPriceUpdateHistoryService, ItemGroupService, paginationConstants, CSVExportService, EXPORT_CONSTANTS, NotificationService,CacheMiddlewareService ) {
    var vm = this;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.itemGroupId=null;
    vm.query = {
      limit: 10,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.barChart=barChart;
    vm.loadItemGroups = loadItemGroups;
    //Header
    vm.topPurchasedItemsHeader = EXPORT_CONSTANTS.CSV.TopPurchasedItemsReport;
    //Function Declaration
    vm.getTopPurchasedItemsCSVData = getTopPurchasedItemsCSVData;
    vm.resetFilters = resetFilters;

    //Getting CSV DATA
    function getTopPurchasedItemsCSVData(){
      var deferred = $q.defer();
      if (!vm.priceUpdateItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getTopPurchasedCSV(vm.priceUpdateItems);
    }
    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadItemGroups() {
      var endpoint = '/api/item-groups';
      var param={page: 0,size: paginationConstants.maxLimit,query: ''};
      if (vm.itemGroups)
          return false;
      vm.itemGroups = null;
      CacheMiddlewareService.getData(endpoint,ItemGroupService.getAllItemGroupsData,param).
          then(function (response) {
            vm.itemGroups = response;
          }, function (error) {
          console.error('Error fetching item Groups:', error);
          });
  }

    function barChart() {
      var priceUpdateItem=vm.priceUpdateItems;
      var arr;
      var counter =0;
      var data = new google.visualization.DataTable();
      data.addColumn('string', 'Items');
      data.addColumn('number', 'values');
      for (priceUpdateItem in vm.priceUpdateItems){
        arr = (vm.priceUpdateItems[priceUpdateItem]);
        data.addRow([arr[1],arr[5]]);
      }
      if(data.getNumberOfRows()==0){}
      else {
        var options = {
          fontName: 'Roboto',
          height: 400,
          fontSize: 12,
          chartArea: {
            left: '5%',
            width: '90%',
            height: 350
          },
          tooltip: {
            textStyle: {
              fontName: 'Roboto',
              fontSize: 13
            }
          },
          legend: {
            position: 'top',
            alignment: 'center',
            textStyle: {
              fontSize: 12
            }
          },
          hAxis: {
            title: 'Item',
            titleTextStyle: {
              fontSize: 13,
              italic: false
            }
          },vAxis: {
            title: 'Values',
            titleTextStyle: {
              fontSize: 13,
              italic: false
            }
          },
          bar: { groupWidth: '25%' },
        };
        var chart = new google.visualization.ColumnChart(document.getElementById('barchart_material'));
        chart.draw(data, options);
      }
    }
    google.charts.load('current', {packages: ['corechart']});
    google.charts.setOnLoadCallback(barChart);

    /**
    * [loadAll loads all price update history]
    */
    function loadAll() {
      SupplierPriceUpdateHistoryService.getTopPurchasedItems({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        fromDate: !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
        supplierId: !vm.supplierId ? '' : vm.supplierId,
        itemGroupId: !vm.itemGroupId ? '' : vm.itemGroupId,
        buyerId: !vm.buyerId ? '' : vm.buyerId,
        itemName: !vm.itemName ? '' : vm.itemName,
        query: '',
      }).then(function(response){
        vm.priceUpdateItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
        vm.barChart=barChart();
      },function(error){
      });
    }
    function resetFilters() {
        vm.fromDate         = vm.todaysDate;
        vm.toDate           = vm.todaysDate;
        vm.itemName         = null;
        vm.itemGroupId      = null;
        vm.priceUpdateItems = null;
        document.getElementById('barchart_material').innerHTML = '';
    }
  }
})();
