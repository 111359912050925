/**
* @Author: Ajay
* @Date:   24-Feb-2017 12:40 +05:30
* @Project: Ezyprocure
* @Filename: buyer.user-access.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 30-Nov-2017 11:32 +05:30
*/



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .controller('SupplierSpecificController', SupplierSpecificController);
  
    SupplierSpecificController.$inject = ['$scope', '$state', '$filter', 'pagingParams', '$mdDialog', 'BuyerUserManagementService', 'UserAccessService','BuyerAccountService','BuyerDataService', 'paginationConstants', 'NotificationService', 'GLOBAL_CONSTANTS', 'Auth','BuyerBusinessUserMapService','BuyerBuyNowService','BuyerItemsForApprovalService','TIME_CONSTANTS','CacheMiddlewareService'];
  
    function SupplierSpecificController($scope, $state, $filter, pagingParams, $mdDialog, BuyerUserManagementService, UserAccessService,BuyerAccountService,BuyerDataService, paginationConstants, NotificationService, GLOBAL_CONSTANTS, Auth,BuyerBusinessUserMapService,BuyerBuyNowService,BuyerItemsForApprovalService,TIME_CONSTANTS,CacheMiddlewareService) {
      var vm = this;
      vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
      vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
      vm.getSupplierDeliveryConditionsList = getSupplierDeliveryConditionsList;
      vm.selectAll = selectAll;
      vm.deselectAll = deselectAll;
      vm.populateSpecificDeliveryConditions = populateSpecificDeliveryConditions;
      vm.orderTimes = TIME_CONSTANTS.timeConstants;
      vm.disabled = true;
      vm.deliveryConditionsToday = [];
      vm.deliveryConditionsFriday = [];
      vm.deliveryConditionsSaturday = [];
      vm.deliveryConditionsMinimum = [];
      vm.filter = {
        buyerBusinessIdList : [],
        supplierIdList : []
      };
      function getAllBuyerBusinessOutlets() {
        var endpoint = '/api/business-outlets';
        if (vm.businessOutlets)
          return false;
        vm.businessOutlets = null;
        CacheMiddlewareService.getData(endpoint,BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser).
          then(function(response) {
            vm.businessOutlets = response;
            //if only one business outlet set it as default
            if (vm.businessOutlets.length === 1) {
              vm.businessId = vm.businessOutlets[0].id;
            }
          }, function(error) {
            NotificationService.error({
              title:"Failed to load business outlets!",
              message:error
            });
          });
      }

      function populateSpecificDeliveryConditions(outletItem,index) {
        if(outletItem.deliveryConditions.orderTimeForToday) {
          vm.deliveryConditionsToday[index] = true;
        }
        if(outletItem.deliveryConditions.orderTimeForFriday) {
          vm.deliveryConditionsFriday[index] = true;
        }
        if(outletItem.deliveryConditions.orderTimeForSaturday) {
          vm.deliveryConditionsSaturday[index] = true;
        }
        if(outletItem.deliveryConditions.minimumOrderForFreeDelivery) {
          vm.deliveryConditionsMinimum[index] = true;
        }
      }

      function getAllAllocatedSuppliersForBusiness(){
      BuyerItemsForApprovalService.getItemBuyerMappingByItemId().then(function(response) {
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
      }, function(error) {
      });
      }

      function selectAll(selectParam) {
        if(selectParam === 'OUTLETS')
        vm.filter.buyerBusinessIdList = _.map(vm.businessOutlets, _.property('id'));
      }
  
      /**
      * [deselectAll Deselect all business outlets from the list]
      */
      function deselectAll(selectParam) {
        if(selectParam === 'OUTLETS')
        vm.filter.buyerBusinessIdList = [];
        }

      function getSupplierDeliveryConditionsList(){
        BuyerBuyNowService.getSpecificSupplierDeliveryConditionsList({
          outletIds: !vm.filter.buyerBusinessIdList ? '': vm.filter.buyerBusinessIdList,
          supplierId: !vm.filter.supplierIdList ?'':vm.filter.supplierIdList
        }).
        then(function(response) {
          vm.specificDeliveryList = response.data;
        }, function(error) {
          NotificationService.error({
            title:"Failed to load business outlets!",
            message:error
          });
        });
      }
    }
  })();
  