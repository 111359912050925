/**
* @Author: Vaibhavi
* @Date:  06-Jun-2018 11:59 +05:30
* @Project: Ezyprocure
* @Filename: buyer.gr-variance-report.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:53 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerGRVarianceController', BuyerGRVarianceController);

  BuyerGRVarianceController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'pagingParams', 'UTCDateService', 'BusinessReportService', 'ItemGroupService', 'paginationConstants', 'BuyerBuyNowService', 'CSVExportService', 'EXPORT_CONSTANTS', 'BuyerBusinessUserMapService','MainItemGroupService', 'NotificationService', 'GLOBAL_CONSTANTS','CacheMiddlewareService'];

  function BuyerGRVarianceController($scope, $rootScope, $q, $filter, $state, $stateParams, toastr, $mdDialog, pagingParams, UTCDateService, BusinessReportService, ItemGroupService, paginationConstants, BuyerBuyNowService, CSVExportService, EXPORT_CONSTANTS, BuyerBusinessUserMapService, MainItemGroupService, NotificationService, GLOBAL_CONSTANTS,CacheMiddlewareService) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.loadItemGroups = loadItemGroups;
    vm.loadAll = loadAll;
    vm.supplier = '';
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.loadMainItemGroups = loadMainItemGroups;
    vm.getAllGroupedItemGroups = getAllGroupedItemGroups;
    vm.mainItemGroupSelectionChanged = mainItemGroupSelectionChanged;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.options={};
    vm.filter = {
      businessOutletIdList: [],
      mainItemGroupIdList: [],
      itemGroupIdList: [],
      supplierIdList: []
    };
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };

    vm.limitOptions = paginationConstants.limitOptions;
    //Header
    vm.GrVarianceReportHeader = EXPORT_CONSTANTS.CSV.GrVarianceReport;
    //Function Declaration
    vm.getGrVarianceCSVData = getGrVarianceCSVData;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.resetFilters = resetFilters;

    //Getting CSV DATA
    function getGrVarianceCSVData() {
      var deferred = $q.defer();
      if (!vm.grVarianceItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getGrVarianceCSVData(vm.grVarianceItems);
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
     
    function getAllBuyerBusinessOutlets() {
      var endpoint = '/api/business-outlets';
      if(vm.businessOutlets)
          return false;
      vm.businessOutlets = null;
      CacheMiddlewareService.getData(endpoint,BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser).then(function(response){
          vm.businessOutlets = response;
          //if only one business outlet set it as default
          if (vm.businessOutlets.length === 1) {
              vm.businessId = vm.businessOutlets[0].id;
          }
      }, function(error){
        NotificationService.error({
          title:"Failed to load BusinessOutlets!",
          message:error
        });
      });
  }

    /**
    * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
    */
    function getAllAllocatedSuppliersForBusiness(){
      if(vm.supplierList)
      return false;
      var params = {'businessId': ''};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
      },function(error){
        NotificationService.error({
          title:"Failed to load Suppliers!",
          message:error
        });
      });
    }

    /**
    *[loadItemGroups loads all the item group]
    */
    function loadItemGroups() {
      var endpoint = '/api/item-groups';
      var param={page: 0,size: paginationConstants.maxLimit,query: ''};
      if (vm.itemGroups)
          return false;
      vm.itemGroups = null;
      CacheMiddlewareService.getData(endpoint,ItemGroupService.getAllItemGroupsData,param).
          then(function (response) {
            vm.itemGroups = response;
          }, function (error) {
            NotificationService.error({
              title:"Failed to load item groups!",
              message:error
            });
          });
  }

    /**
    *[loadAll loads the gr variance report for a particular period]
    */
    function loadAll() {
      BusinessReportService.getGRVarianceReport({
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        buyerBusinessIdList: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList ,
        supplierIdList: !vm.filter.supplierIdList ? []: vm.filter.supplierIdList,
        mainItemGroupIdList: !vm.filter.mainItemGroupIdList ? []: vm.filter.mainItemGroupIdList,
        itemGroupIdList: !vm.filter.itemGroupIdList ? [] :vm.filter.itemGroupIdList,
        poNumber: !vm.filter.poNumber ? '': vm.filter.poNumber,
        varianceRateLimit: !vm.filter.varianceRateLimit? vm.filter.varianceRateLimit=10 : Math.abs(vm.filter.varianceRateLimit),
        query: ''
      },
      {
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      }).then(function(response) {
        vm.grVarianceItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function(error) {
        NotificationService.error({
          title:"Failed to load GR Variance Report!",
          message:error
        });
      });
    }

    /**
    * [loadMainItemGroups Gets all Main Item Groups]
    */
    function loadMainItemGroups() {
      if (vm.mainItemGroups) {
        return false;
      }
      MainItemGroupService.getAllMainItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.mainItemGroups = response.data;
      }, function(error) {
        NotificationService.error({
          title:"Failed to load Main Item Group!",
          message:error
        });
      });
    }

    /**
    * [mainItemGroupChanged changes the status of mainItemGroup and empties the existing itemGroupList]
    */
    function mainItemGroupSelectionChanged() {
      vm.filter.mainItemGroupChanged = true;
      vm.filter.itemGroupIdList = [];
    }

    /**
    * [selectAll Selects all from the list]
    * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups, itemGroups ]
    */
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'OUTLETS':
        vm.filter.buyerBusinessIdList = _.map(vm.businessOutlets, _.property('id'));
        break;
        case 'SUPPLIERS':
        vm.filter.supplierIdList = _.map(vm.supplierList, _.property('id'));
        break;
        case 'ITEMGROUPS':
        vm.filter.itemGroupIdList = _.map(_.flatten(_.without(_.map(vm.itemGroups, _.property('itemGroupDTOList')), undefined, null)), _.property('itemGroupId'));
        break;
      }
    }

    /**
    * [deselectAll Deselect all from the list]
    * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups, itemGroups ]
    */
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'OUTLETS':
        vm.filter.buyerBusinessIdList = [];
        break;
        case 'SUPPLIERS':getAllAllocatedSuppliersForBusiness
        vm.filter.supplierIdList = [];
        break;
        case 'ITEMGROUPS':
        vm.filter.itemGroupIdList = [];
        break;

      }
    }

    /**
    * [getAllGroupedItemGroups Gets all ItemGroups Grouped by Main Item Groups]
    */
    function getAllGroupedItemGroups(mainItemGroupIdList) {
      if (vm.itemGroups && !vm.filter.mainItemGroupChanged){
        return false;
      }
      vm.filter.mainItemGroupChanged = false;
      ItemGroupService.getAllGroupedItemGroups(mainItemGroupIdList, {
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
        NotificationService.error({
          title:"Failed to load item groups for main item group!",
          message:error
        });
      });
    }
    function resetFilters() {
        vm.filter = {};
        vm.filter.fromDate = vm.todaysDate;
        vm.filter.toDate = vm.todaysDate;
        vm.grVarianceItems = null;
      }
  }

}) ();
