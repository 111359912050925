/**
 * @Author: Atul
 * @Date:   29-June-2024 02:34 +05:30
 * @Project: Ezyprocure
 * @Filename: cache-middleware.service.js
 * @Last modified by:   Atul
 * @Last modified time: 29-June-2024
 */

(function() {
    'use strict';
  
    angular.module('ezyprocureApp').factory('CacheMiddlewareService', CacheMiddlewareService);
  
    CacheMiddlewareService.$inject = ['$http', '$q', 'toastr', 'TIME_CONSTANTS','$localStorage'];
  
    function CacheMiddlewareService($http, $q, toastr, TIME_CONSTANTS, $localStorage) {
  
        var cacheKeyPrefix = 'cache-middleware-'; // Prefix for cache keys
        var freshnessThreshold = TIME_CONSTANTS.CACHE_FRESHNESS_THRESHOLD || 3600000; // Default: 1 hour in milliseconds
  
        var service = {
            getData: getData
        };
        return service;
  
        // Function to get data from cache or fetch from API
        function getData(endpoint, fetchFunction, params, simpleResponse) {
            simpleResponse = true;
            var deferred = $q.defer();
  
            var cachedData = getCachedData(endpoint);
  
            // Check if cached data is fresh
            if (cachedData && isDataFresh(cachedData.timestamp)) {
                deferred.resolve(cachedData.data);
            } else {
                // Fetch data from API using provided fetch function
                fetchFunction(params).then(function(data) {
                    saveDataToCache(endpoint, data, simpleResponse);
                    deferred.resolve(data);
                }).catch(function(error) {
                    deferred.reject(error);
                    toastr.error('Error fetching data: ' + error.statusText, 'Error');
                });
            }
  
            return deferred.promise;
        }
  
        // Function to check if cached data is fresh
        function isDataFresh(timestamp) {
            return (new Date().getTime() - timestamp) < freshnessThreshold;
        }
  
        // Function to get cached data from localStorage
        function getCachedData(endpoint) {
            return $localStorage[cacheKeyPrefix + endpoint];
        }
  
        // Function to save data to cache with timestamp
        function saveDataToCache(key, response, simpleResponse) {
            var cleanedData = simpleResponse ? response : response.data;
  
            try {
                var cacheData = {
                    data: cleanedData,
                    timestamp: new Date().getTime()
                };
                $localStorage[cacheKeyPrefix + key] = cacheData;
            } catch (error) {
                console.error('Error saving data to $localStorage:', error);
            }
        }
    }
  })();
  