/*
 * @Author: Lovin 
 * @Date: 2018-11-13 18:57:57 
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2020-05-28 16:51:14
 */
(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('GlMappingEditController', GlMappingEditController);

  GlMappingEditController.$inject = ['$scope', '$rootScope', '$state', 'toastr', '$stateParams', '$mdDialog', 'GlMappingService', 'pagingParams', 'PurchaseOrderTypeService', 'GlSpecificItemGroupService', 'GlCodeService', 'ItemGroupService', 'paginationConstants', 'NotificationService','CacheMiddlewareService'];

  function GlMappingEditController($scope, $rootScope, $state, toastr, $stateParams, $mdDialog, GlMappingService, pagingParams, PurchaseOrderTypeService, GlSpecificItemGroupService, GlCodeService, ItemGroupService, paginationConstants, NotificationService,CacheMiddlewareService) {

    var vm = this;
    vm.glMappingId = $stateParams.glMappingId;
    vm.getGlMapping = getGlMapping;
    vm.loadPurchaseOrderTypes = loadPurchaseOrderTypes;
    vm.loadItemGroups = loadItemGroups;
    vm.loadGlSpecificItemGroups = loadGlSpecificItemGroups;
    vm.loadGlCodes = loadGlCodes;
    vm.showAddGlCode = showAddGlCode;
    vm.addGlCode = addGlCode;
    vm.showAddGlSpecificItemGroup = showAddGlSpecificItemGroup;
    vm.addGlSpecificItemGroup = addGlSpecificItemGroup;
    vm.cancel = cancel;
    vm.modifyGlMapping = modifyGlMapping;
    vm.isGlMapped = isGlMapped;
    vm.init = init;
    vm.isAlreadyMapped = true;
    
    /**
* [getGlMapping - Fetches the GlMapping using brand id]
*/
    function getGlMapping(id) {
      GlMappingService.getGlmappingById(id).
        then(function (response) {
          if (response) {
            vm.editGlMapping = response;
          }
        }, function (error) {
        });
    }

    function init() {
      vm.loadPurchaseOrderTypes();
      vm.loadItemGroups();
      vm.loadGlSpecificItemGroups();
      vm.loadGlCodes();
    }

    vm.init();
  /**
   * loadPurchaseOrderTypes gets all the Purchase Order Types
   */
    function loadPurchaseOrderTypes() {
      PurchaseOrderTypeService.getAllPurchaseOrderTypes({
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function (response) {
        vm.purchaseOrderTypes = response.data;
      }, function (error) {
      });
    }

  /**
   * loadItemGroups gets all Item Groups
   */
  function loadItemGroups() {
    var endpoint = '/api/item-groups';
    var param={page: 0,size: paginationConstants.maxLimit,query: ''};
    if (vm.itemGroups)
        return false;
    vm.itemGroups = null;
    CacheMiddlewareService.getData(endpoint,ItemGroupService.getAllItemGroupsData,param).
        then(function (response) {
          vm.itemGroups = response;
        }, function (error) {
        console.error('Error fetching item Groups:', error);
        });
}

    /**
     *  loadGlSpecificItemGroups gets all Gl Specific Item Groups
     */
    function loadGlSpecificItemGroups() {
      GlSpecificItemGroupService.getAllGlSpecificItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function (response) {
        vm.glSpecificItemGroups = response.data;
      }, function (error) {
      });
    }

    /**
     * loadGlCodes gets all Gl-Codes
     */
    function loadGlCodes() {
      GlCodeService.getAllGlCodes({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function (response) {
        vm.glCodes = response.data;
      }, function (error) {
      });
    }

    //Add GL Code Starts here
    /**
     * Show modal to add Gl Code
     * @param {*} event 
     */
    function showAddGlCode(event) {
      vm.newGlCode = {};
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/gl-module/gl-codes/buyer.gl-code.add.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    /**
     * addGlCode adds new GL-code
     * @param {*} newGlCode 
     */
    function addGlCode(newGlCode) {
      GlCodeService.createGlCode(newGlCode).then(function (response) {
        $scope.$emit('ezyprocureApp:glCodeUpdate', response);
        vm.cancel();
        vm.glCodes.push(response);
        vm.editGlMapping.glCodes.id = response.id;
        vm.isGlMapped(vm.editGlMapping);
      }, function (error) {
        NotificationService.error({
          title: 'GL Code Creation',
          error: error
        });
      });
    }

    //Add GlCode Ends here

    //Add Gl Specific Item Group Starts here
    /**
     * Show modal to add Gl Specific Item Group
     * @param {*} event 
     */
    function showAddGlSpecificItemGroup(event) {
      vm.newGlSpecificItemGroup = {};
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/gl-module/gl-specific-item-groups/buyer.gl-specific-item-group.add.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }
    /**
     * addGlSpecificItemGroup adds new Gl Specific Item Group
     * @param {*} newGlSpecificItemGroup 
     */
    function addGlSpecificItemGroup(newGlSpecificItemGroup) {
      GlSpecificItemGroupService.createGlSpecificItemGroup(newGlSpecificItemGroup).then(function (response) {
        $scope.$emit('ezyprocureApp:glSpecificItemGroupUpdate', response);
        vm.cancel();
        vm.glSpecificItemGroups.push(response);
        vm.editGlMapping.glSpecificItemGroups.id = response.id;
        vm.isGlMapped(vm.editGlMapping);
      }, function (error) {
        NotificationService.error({
          title: 'GL Specific Item Group Creation',
          error: error
        });
      });
    }

    function cancel() {
      $mdDialog.cancel();
    }

    //Add GlSpecificItemGroup Ends here

    /**
     * isGlMapped returns true when the Gl Mapping exists 
     * @param {*} glMapping 
     */
    function isGlMapped(glMapping) {
      if (!glMapping.purchaseOrderTypes.id || !glMapping.itemGroup.id || !glMapping.glCodes.id || !glMapping.glSpecificItemGroups.id)
        return false;
      GlMappingService.isGlMapped({
        purchaseOrderTypeId: glMapping.purchaseOrderTypes.id,
        glSpecificItemGroupId: glMapping.glSpecificItemGroups.id,
        itemGroupId: glMapping.itemGroup.id,
        glCodeId: glMapping.glCodes.id
      }).then(function (response) {
        vm.isAlreadyMapped = response;
      }, function (error) {
        NotificationService.error({
          title: 'GL Mapping isGlMapped',
          error: error
        });
      });
    }

    /**
     * edits the existing mapping
     */
    function modifyGlMapping() {
      GlMappingService.modifyGlMapping(vm.editGlMapping).then(function (response) {
        NotificationService.success({
          title: 'GL Mapping',
          message: 'Mapped successfully'
        });
        $state.go('buyer.gl-module.gl-mapping', {
          reload: false               
         });
        vm.glMappings = response.data;
      }, function (error) {
        NotificationService.error({
          title: 'GL Mapping Creation',
          error: error
        });
      });
    }
    
    vm.getGlMapping(vm.glMappingId);
  }
})();