/**
* @Author: Allan
* @Date:   19-Feb-2019 17:54 +05:30
* @Project: Ezyprocure
* @Filename: buyer.buy-now-mobile.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-05-31T11:21:24+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyNowMobileItemsController', BuyNowMobileItemsController);

  BuyNowMobileItemsController.$inject = ['$state', '$scope', '$stateParams', '$rootScope', '$filter', 'paginationConstants', 'BuyerBuyNowService', 'NotificationService', 'pagingParams', '$mdSidenav', 'PurchaseOrderTypeService', 'BuyerBusinessUserMapService', 'CartService', 'UTCDateService', 'Auth', '$mdDialog', 'PromotionService', 'BuyerOrderTemplateService', 'BuyerPurchaseApprovalRequestService','CacheMiddlewareService'];

  function BuyNowMobileItemsController($state, $scope, $stateParams, $rootScope, $filter, paginationConstants, BuyerBuyNowService, NotificationService, pagingParams, $mdSidenav, PurchaseOrderTypeService, BuyerBusinessUserMapService, CartService, UTCDateService, Auth, $mdDialog, PromotionService, BuyerOrderTemplateService, BuyerPurchaseApprovalRequestService,CacheMiddlewareService) {
    var vm = this;
    vm.init = init;
    vm.tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

    //variables/objects related to buy now items
    vm.items = []; // needs to defined as empty array so that concat method will not throw error for undefined case on first call
    vm.searchText = '';
    vm.cartId = null;
    vm.approvalRequestNumber = $stateParams.approvalRequestNumber;


    //filter params variables.objects
    vm.predicate = 'items.itemName';
    vm.reverse = pagingParams.ascending;

    //filter object defined by storing state url parameters passed from filter page 1
    vm.filter = {
      'supplierVisibilityType': 'allocated',
      'businessId': undefined,
      'businessOutletName': '',  // to be populated in filter panel at the top of item list
      'supplierId': undefined,
      'supplierName': '',  // to be populated in filter panel at the top of item list
      'purchaseOrderTypeId': undefined,
      'purchaseMethod': 'PURCHASE_BY_ITEM',
      'templateId': +$stateParams.templateId ? $stateParams.templateId : undefined
    };

    // methods - common
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.proceedToCheckout = proceedToCheckout;
    vm.initializeSideFilter = initializeSideFilter;
    vm.addToItemApprovalRequest = addToItemApprovalRequest;

    //filter param methods - PURCHASE_BY_ITEM
    vm.loadPurchaseOrderTypes = loadPurchaseOrderTypes;
    vm.getSuppliersByBusinessOutlet = getSuppliersByBusinessOutlet;
    vm.getFilterParamNames = getFilterParamNames;
    vm.filterQueryChanged = filterQueryChanged;
    vm.getAllPublicSuppliers = getAllPublicSuppliers;
    vm.supplierVisibilityTypeChanged = supplierVisibilityTypeChanged;
    vm.businessOutletChanged = businessOutletChanged;
    vm.setDefaultPurchaseOrderType = setDefaultPurchaseOrderType;

    // filter param methods - PURCHASE_BY_TEMPLATE
    vm.getTemplatesByOutlet = getTemplatesByOutlet;
    vm.validateSupplierList = validateSupplierList;

    //side navbar close and open methods
    vm.openSideNav = openSideNav;
    vm.closeSideNav = closeSideNav;
    vm.cancelSideNav = cancelSideNav;
    vm.openFilterNav = vm.openSideNav('closeEventsDisabled');
    vm.closeFilterNav = vm.closeSideNav('closeEventsDisabled');
    vm.cancelFilterNav = vm.cancelSideNav('closeEventsDisabled');

    //item methods and related pagination  methods
    vm.changeDeliveryDate = changeDeliveryDate;
    vm.loadItems = loadItems;
    vm.loadOrderTemplateDetails = loadOrderTemplateDetails; // order template - load items and other relevant details
    vm.saveAdhoc = saveAdhoc;
    vm.showMore = showMore;
    vm.resetPaginationQuery = resetPaginationQuery;
    vm.removeFromCartConfirmationModal = removeFromCartConfirmationModal;
    vm.addToCart = addToCart;
    vm.addTemplateItemsToCart = addTemplateItemsToCart; // order template - add to cart
    vm.getSelectedTemplateItems = getSelectedTemplateItems; // order template method - intermediate method before add to cart- get template items for which the user has added the quantity
    vm.removeFromCart = removeFromCart;
    vm.cancel = cancel;
    vm.getBuyerPromotions = getBuyerPromotions;
    vm.getPromotionTicker = getPromotionTicker;
    vm.hidePromotions = hidePromotions;
    vm.addToFavorites = addToFavorites;
    vm.removeFromFavorites = removeFromFavorites;
    vm.toggleItemLoad = toggleItemLoad;

    // item buy-now/cart validation methods
    vm.checkItemInCart = checkItemInCart;
    vm.checkBusinessOutletSelection = checkBusinessOutletSelection;
    vm.checkPurchaseMethod = checkPurchaseMethod;
    vm.changePurchaseMethod = changePurchaseMethod;
    vm.showDeleteCartConfirmationModal = showDeleteCartConfirmationModal;
    vm.clearCart = clearCart;
    vm.validateTemplateList = validateTemplateList;
    vm.isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent); // detect mobile OS and implement accordion button if IOS (accordion  panel does not open in IOS and hence separate button implemented for IOS for expanding accordion)

    //unsorted functions
    vm.transition = transition;


    // ------------------initialization -----------------------------//
    /**
    * [init initialize methods on page load]
    * @return {[type]} [description]
    */
    function init(){
      vm.resetPaginationQuery();
      vm.itemListType = 'all';
      vm.filter.purchaseMethod = 'PURCHASE_BY_ITEM'
      vm.checkPurchaseMethod();
      vm.getBuyerPromotions();
      vm.getAllBuyerBusinessOutlets();
    }

    /**
    * [changePurchaseMethod check purchase method and call the appropriate method for loading items]
    * @return {[type]} [description]
    */
    function changePurchaseMethod(){
      // vm.filter.businessId = undefined;
      vm.filter.supplierId = undefined;
      vm.searchText = '';
      vm.getAllBuyerBusinessOutlets();
      switch(vm.filter.purchaseMethod){
        case 'PURCHASE_BY_ITEM':
        vm.filter.supplierVisibilityType = vm.filter.supplierVisibilityType? vm.filter.supplierVisibilityType : 'allocated'
        vm.items = [];
        vm.businessOutlets = undefined;
        vm.supplierList =  undefined;
        vm.purchaseOrderTypes = undefined;
        vm.loadItems();
        break;
        case 'PURCHASE_BY_TEMPLATE':
        vm.filter.supplierVisibilityType =  'allocated';
        vm.filter.templateId = undefined;
        vm.filter.supplierList = undefined;
        vm.items = [];
        vm.purchaseOrderTypes = undefined;
        vm.loadOrderTemplateDetails();
        CartService.getCartDetails().then(function (response) {
          var count = response.cartCount ? response.cartCount : 0;
          if (count > 0) {
            vm.showDeleteCartConfirmationModal();
          }
        }, function (error) {
          NotificationService.error({
            title: 'Buyer Cart',
            error: error
          });
        });
        break;
      }
    }

    /**
    * [showDeleteCartConfirmationModal delete cart confirmation modal]
    * @param  {[type]} ev           [description]
    * @param  {[type]} supplierItem [description]
    * @return {[type]}              [description]
    */
    function showDeleteCartConfirmationModal(ev, supplierItem) {
      var confirm = $mdDialog.confirm()
      .title($filter('translate')('alertMessage.CONFIRM_CLEAR_CART'))
      .textContent($filter('translate')('alertMessage.COULD_NOT_BE_REVERTED_BACK'))
      .targetEvent(ev)
      .ok($filter('translate')('global.buttons.clear'))
      .cancel($filter('translate')('global.buttons.cancel'));

      $mdDialog.show(confirm).then(function () {
        vm.clearCart();
      }, function () {
        vm.filter.purchaseMethod='PURCHASE_BY_ITEM';
        vm.changePurchaseMethod();
      });
    }

    /**
    * [clearCart resets cart if purchase by template]
    * @return {[type]} [description]
    */
    function clearCart() {
      BuyerBuyNowService.resetCart().then(function (response) {
        NotificationService.success({
          title: 'global.menu.entities.cart',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        $rootScope.$broadcast('refresh-cart');
      }, function (error) {
        NotificationService.error({
          title: 'Order Templates',
          error: error
        });
      });
    }


    /**
    * [checkPurchaseMethod check purchase method and call the appropriate method for loading items]
    * @return {[type]} [description]
    */
    function checkPurchaseMethod(){
      switch(vm.filter.purchaseMethod){
        case 'PURCHASE_BY_ITEM':
        vm.filter.supplierVisibilityType = vm.filter.supplierVisibilityType? vm.filter.supplierVisibilityType : 'allocated'
        vm.loadItems();
        break;
        case 'PURCHASE_BY_TEMPLATE':
        vm.filter.supplierVisibilityType = 'allocated';
        vm.items = [];
        vm.loadOrderTemplateDetails();
        break;
      }
    }

    /**
    * [initializeSideFilter initialize side nav filter]
    * @return {[type]} [description]
    */
    function initializeSideFilter(){
      switch(vm.filter.purchaseMethod){
        case 'PURCHASE_BY_ITEM':
        vm.purchaseOrderTypes = undefined;
        break;
        case 'PURCHASE_BY_TEMPLATE':
        vm.getTemplatesByOutlet();
        vm.loadOrderTemplateDetails();
        break;
      }

    }

    /**
    * [changeDeliveryDate change delivery date of each item on change of common delivery date]
    * @return {[type]} [description]
    */
    function changeDeliveryDate(){
      var tempItemDetails = _.each(vm.items, function(element) {
        element['deliveryDate']= vm.selectedDate.deliveryDate;
      });
      vm.items = angular.copy(tempItemDetails);
    }

    // ------------------initialization end -----------------------------//



    // --------------------  PAGINATION METHODS ------------------------------//

    /**
    * [resetPaginationQuery reset pagination - may not be called in mobile]
    */
    function resetPaginationQuery() {
      vm.query = {
        limit: 20,
        page: 1
      }
    }

    /**
    * [sort method for item sort]
    * @return {[type]} [description]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [showMore increments the page and calls items api and concatenates --> called by infinite scroll directive in mobile view]
    * @return {[type]} [description]
    */
    function showMore() {
      if(vm.itemListLoaded && vm.items){
        if(vm.items.length < vm.totalItems){
          vm.query.page += 1;
          vm.checkPurchaseMethod();
        }
      }
    }

    // --------------------   PAGINATION METHODS end ------------------------------//


    //-------------------  SIDE FILTER NAVBAR TOGGLE AND UPDATE methods --------------------------//

    /**
    * [closeSideNav called when sidenav is closed]
    * @param  {[type]} componentId [description]
    * @return {[type]}             [description]
    */
    function closeSideNav(componentId) {
      return function() {
        vm.items = [];
        vm.checkPurchaseMethod();
        $mdSidenav(componentId).toggle();
      };
    }

    /**
    * [closeSideNav called when sidenav is closed]
    * @param  {[type]} componentId [description]
    * @return {[type]}             [description]
    */
    function cancelSideNav(componentId) {
      return function() {
        vm.filter = angular.copy(vm.tempFilter); // restore original filter object state when user cancels the sidenav filter
        $mdSidenav(componentId).toggle();
      };
    }

    /**
    * [openSideNav called when sidenav is opened]
    * @param  {[type]} componentId [description]
    * @return {[type]}             [description]
    */
    function openSideNav(componentId) {
      return function() {
        vm.tempFilter = angular.copy(vm.filter);
        $mdSidenav(componentId).toggle();
      };
    }
    //-------------------  SIDE FILTER NAVBAR TOGGLE AND UPDATE end --------------------------//


    //----------------------- METHODS - Common to both template and item purchase method------------------------------------//

    /**
    * [getFilterParamNames sets the main title of the item listing panel with the main params selected by the user]
    * @param  {[type]} paramName [parameter name to be set]
    * @return {[type]}           [required parameter value]
    */
    function getFilterParamNames(paramName){
      switch(paramName){
        case 'businessOutlet':
        var selectedBusinessOutlet =  _.find(vm.businessOutlets, ['id', vm.filter.businessId]);
        return selectedBusinessOutlet.businessOutletName;
        break;
        case 'supplier':
        var selectedSupplier =  _.find(vm.supplierList, ['id', vm.filter.supplierId]);
        return selectedSupplier.companyName;
        break;
      }
    }

    /**
    * [addToItemApprovalRequest add an item to item approval request]
    * @param {[type]} itemToBuy [description]
    */
    function addToItemApprovalRequest(itemToBuy) {
      var cartItem = {};
      if (CartService.checkOrderedTime(itemToBuy, vm.filter) && CartService.checkDeliveryQuantity(itemToBuy) && vm.checkBusinessOutletSelection()) {
        cartItem.supplierType = vm.filter.supplierVisibilityType;
        cartItem.deliveryDate = UTCDateService.setCurrentTimeToDate(itemToBuy.deliveryDate);
        cartItem.quantity = itemToBuy.quantity;
        cartItem.specialRequest = itemToBuy.specialRequest ? itemToBuy.specialRequest : null;
        cartItem.supplierId = vm.filter.supplierId;
        cartItem.items = {};
        cartItem.buyerBusiness = {};
        cartItem.buyerBusiness.id = vm.filter.businessId;
        if(Auth.hasPermission('accessForGlModule')){
          cartItem.glCodeMappings = {};
          cartItem.glCodeMappings.purchaseOrderTypes = {};
          cartItem.glCodeMappings.purchaseOrderTypes.id = vm.filter.purchaseOrderTypeId;
        }
        cartItem.items.id = itemToBuy.itemId;
        BuyerPurchaseApprovalRequestService.addToPurchaseApprovalRequest(cartItem, vm.approvalRequestNumber).
        then(function(responseData) {
          NotificationService.success({
            title: 'global.buttons.addToItemApproval',
            message: 'alertMessage.COMPLETED_SUCCESSFULLY'
          });
          vm.cancel();
          $rootScope.$broadcast('refresh-cart');
        }, function(error) {
          NotificationService.error({
            title: 'global.buttons.addToItemApproval',
            error: error
          });
        });
      }
    }


    /**
    * [getAllBuyerBusinessOutlets get loogged-in buyer's business outlets]
    * @return {[type]} [description]
    */
    function getAllBuyerBusinessOutlets() {
      var endpoint = '/api/business-outlets';
      if(vm.businessOutlets)
          return false;
      vm.businessOutlets = null;
      CacheMiddlewareService.getData(endpoint,BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser).then(function(response){
          vm.businessOutlets = response;
          if(vm.businessOutlets.length === 1) {
            vm.filter.businessId = vm.businessOutlets[0].id;
            vm.filter.businessOutletName =  vm.businessOutlets[0].businessOutletName;
            vm.setDefaultPurchaseOrderType();
          }
          vm.filterQueryChanged();
          vm.getTemplatesByOutlet();
      }, function(error){
          console.error('Error fetching business outlets:', error);
      });
    }

    /**
    * [proceedToCheckout redirects to checkout after setting values or calling respective cart method if required]
    * @return {[type]} [description]
    */
    function proceedToCheckout() {
      if (vm.filter.purchaseMethod == 'PURCHASE_BY_TEMPLATE') {
        if(vm.buyNowListForm.$valid){
          vm.addTemplateItemsToCart();
        }
      }
      if (vm.filter.purchaseMethod == 'PURCHASE_BY_ITEM') {
        $state.go('buyer.cart-checkout-mobile',{
          reload: false
        });
      }
    }

    //-----------------------  METHODS - Common to both template and item purchase method- end------------------------------------//


    //--------------------  FILTER PARAM methods - PURCHASE_BY_ITEM--------------------------------------------//


    /**
    * [filterQueryChanged reset the filters accordingly]
    * @return {[type]} [description]
    */
    function filterQueryChanged() {
      if(vm.filter.purchaseMethod === 'PURCHASE_BY_ITEM'){
        vm.resetPaginationQuery();
        if(vm.filter.businessId && ! vm.filter.supplierId) {
          vm.items = [];
          vm.supplierVisibilityTypeChanged();
        }
        if (vm.filter.businessId || !vm.filter.businessId) {
          vm.items = [];
          vm.loadItems();
        }
        if(vm.filter.supplierId) {
          vm.items = [];
          vm.selectedSupplier = _.find(vm.supplierList, function(o) {
            return o.id === vm.filter.supplierId;
          });
        }
      }
    }

    /**
    * [getAllPublicSuppliers Get all public suppliers]
    * @return {[type]} [description]
    */
    function getAllPublicSuppliers() {
      BuyerBuyNowService.getAllPublicSuppliers().then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
      },function(error){
      });
    }

    //change the supplier type
    function supplierVisibilityTypeChanged() {
      //clear supplierId from itemListQuery used for filter
      vm.filter.supplierId = null;
      //clear existing supplier list
      vm.supplierList = null;
      var visibilityType = angular.copy(vm.filter.visibilityType);
      if(vm.filter && vm.filter.businessId) {
        if(visibilityType === 'PUBLIC') {
          vm.getAllPublicSuppliers();
        }
        else {
          vm.getSuppliersByBusinessOutlet();
        }
      }
      vm.loadItems();
    }


    /**
    * [getSuppliersByBusinessOutlet get suppliers according to chosen businessOutletId and supplierVisibilityType]
    * @return {[type]} [description]
    */
    function getSuppliersByBusinessOutlet(){
      // get business outlet ID
      if(vm.filter.businessId){
        var params = {'businessId': vm.filter.businessId, 'supplierVisibilityType': vm.filter.supplierVisibilityType};
        BuyerBuyNowService.getAllAllocatedSuppliersForBusiness1(params).then(function(response){
          vm.supplierList = $filter('orderBy')(response.data, 'companyName');
          //if only one allocated supplier set it as default
          if(vm.supplierList.length === 1) {
            vm.filter.supplierId = vm.supplierList[0].id;
            vm.filterQueryChanged();
          }
          if(vm.supplierList.length >0)
          return true;
          else {
            NotificationService.simpleErrorToast({
              title: 'global.table.noRecordsFound',
              message: 'alertMessage.PLEASE_SELECT_ANOTHER_OUTLET'
            });
            return false;
          }
        },function(error){
          return false;
        });
      }
      else {
        return false;
      }
    }

    /**
    * [validateSupplierList validates whether supplier list is present or not - else the user will be prompted to select another outlet]
    * @return {[type]} [description]
    */
    function validateSupplierList(){
      if(!vm.supplierList || vm.supplierList.length <= 0){
        if(!vm.getSuppliersByBusinessOutlet()){
          if(!vm.filter.businessId){
            NotificationService.simpleErrorToast({
              title: 'global.table.noRecordsFound',
              message: 'global.pleaseSelectABusinessOutletToStart'
            });
            return false;
          }
          else{
            NotificationService.simpleErrorToast({
              title: 'global.table.noRecordsFound',
              message: 'alertMessage.PLEASE_SELECT_ANOTHER_OUTLET'
            });
            return false;
          }
        }
      }
    }


    /**
    * [loadPurchaseOrderTypes load all purchase order types]
    * @return {[type]} [description]
    */
    function loadPurchaseOrderTypes() {
      if(vm.purchaseOrderTypes)
      return false;
      PurchaseOrderTypeService.getAllPurchaseOrderTypes({
        page: 0,
        size: paginationConstants.maxLimit,
        sort: ''
      }).then(function(response) {
        vm.purchaseOrderTypes = response.data;
      }, function(error) {
      });
    }

/**
 * [setDefaultPurchaseOrderType set default purchaseOrderType corresponding to selected outlet]
 */
    function setDefaultPurchaseOrderType() {
      var selectedOutlet = _.find(vm.businessOutlets, ['id', vm.filter.businessId]);
      vm.filter.purchaseOrderTypeId = selectedOutlet.defaultPurchaseOrderType ? selectedOutlet.defaultPurchaseOrderType.id : undefined;
    }

    //change the business outlet
    function businessOutletChanged() {
      vm.setDefaultPurchaseOrderType();
      vm.supplierVisibilityTypeChanged();
      vm.filterQueryChanged();
    }



    //-------------------------  FILTER PARAM - PURCHASE_BY_ITEM end--------------------------------------------//

    //-------------------------  FILTER PARAM - PURCHASE_BY_TEMPLATE -------------------------------------------//

/**
 * [validateTemplateList validate template list if user tries to open template dropdown/ accordion before selecting outlets]
 * @return {[type]} [description]
 */
    function validateTemplateList(){
      if(!vm.templateList || vm.templateList.length <= 0){
        if(!vm.getTemplatesByOutlet()){
          if(!vm.filter.businessId){
            NotificationService.simpleErrorToast({
              title: 'global.table.noRecordsFound',
              message: 'global.pleaseSelectABusinessOutletToStart'
            });
            return false;
          }
        }
      }
    }


    /**
    * [getTemplatesByOutlet get template list by outlet id]
    * @return {[type]} [description]
    */
    function getTemplatesByOutlet(){
      if(vm.filter.purchaseMethod === 'PURCHASE_BY_TEMPLATE' && vm.filter.businessId){
        BuyerOrderTemplateService.getAllOrderTemplateByOutlets({'buyerBusinessId' :vm.filter.businessId}).then(function (response) {
          if (response.data.length === 0) {
            NotificationService.simpleErrorToast({
              title: 'global.purchaseMethod',
              message: 'alertMessage.PLEASE_SELECT_ANOTHER_OUTLET_FOR_TEMPLATE'
            });
            vm.templateList = [];
            vm.filter.supplierList = []; // order template can be created for multiple suppliers
          }
          else {
            vm.templateList = response.data;
          }
        }, function (error) {
          NotificationService.error({
            title: 'Order Templates',
            error: error
          });
        });
      }
      else {
        return false;
      }
    }
    //-------------------------  FILTER PARAM - PURCHASE_BY_TEMPLATE end-------------------------------------------//


    //-------------------------  PROMOTIONS ------------------------------------------------//


    /**
    * [getBuyerPromotions Get all promotions for the buyer starting from today]
    * @return {[type]} [description]
    */
    function getBuyerPromotions() {
      PromotionService.getAllPromotionItemsForBuyer({
        page: 0,
        size: 10,
        sort: 'effectiveStartDate,asc',
        searchTerm: '',
        startDate: new Date(),
        endDate: '',
        flag:"BUY_NOW",
        buyerBusinessId : vm.itemListQuery.businessId
      }).then(function(response) {
        vm.showPromotionTicker = true;
        vm.promotions = response.data;
      }, function(error) {
        vm.promotions = null;
      });
    }

    /**
    * [hidePromotions hide promotion - may not be required for mobile view]
    * @return {[type]} [description]
    */
    function hidePromotions() {
      vm.showPromotionTicker = !vm.showPromotionTicker;
    }

    /**
    * [getPromotionTicker get promotion details by item index and return the constructed the promotion content]
    * @param  {[type]} promotionItem [promotionItem]
    * @param  {[type]} itemIndex     [item index]
    * @return {[type]}               [description]
    */
    function getPromotionTicker(promotionItem, itemIndex) {
      var promotionTicker = '';
      if(promotionItem){
        promotionTicker = 'Buy '
        + promotionItem.applicablePromotionQuantity + ' '
        + promotionItem.promotionItem.itemName + ' and get '
        + promotionItem.freeQuantity + ' ' + promotionItem.freeItem.itemName
        + ' free! | \n (Promo Duration) '
        + $filter('date')(promotionItem.effectiveStartDate, "dd/MM/yyyy") + ' to '
        + $filter('date')(promotionItem.effectiveEndDate, "dd/MM/yyyy")
        + ' ( ' + promotionItem.promotionItem.supplier.companyName + ' ) ';
      }
      return promotionTicker;
    }
    //-------------------------  PROMOTIONS end --------------------------------------------//


    //---------------------------BUY NOW/CART ITEM DATA METHODS ---------------------------//

    /**
    * [loadItems load items based on the applied filters - PURCHASE BY ITEMS]
    * @return {[type]} [description]
    */
    function loadItems(){
      vm.recordError = [];
      BuyerBuyNowService.getAllItemBuyerMappingsByBuyer(vm.filter,{
        supplierId: vm.filter.supplierId ? vm.filter.supplierId : '',
        businessId: vm.filter.businessId ? vm.filter.businessId : '',
        adhocPoType: vm.filter.supplierVisibilityType ? vm.filter.supplierVisibilityType : '',
        view: vm.itemListType,
        query: vm.searchText,
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      }).then(function(response){
        if(vm.query.page === 1)
        vm.items = [];
        vm.disableCommonDate =  true; // boolean value for common delivery date to be disabled or not
        vm.totalItems = response.headers('X-Total-Count');
        vm.recordError = [];

        // item parameters to be set on load
        _.each(response.data, function(element, index) {
          _.extend(element,
            {
              deliveryDate: (element.supplier.noSameDayDelivery)? (angular.copy(new Date(new Date().getTime() + ((element.supplier.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(vm.tomorrow)),
              quantity: angular.copy(element.inCartQuantity),
              minDate: (element.supplier.noSameDayDelivery)?(angular.copy(new Date(new Date().getTime() + ((element.supplier.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(new Date(new Date().getTime())))
            })
          });
          vm.items = vm.items.concat(response.data); // concat for mobile view on virtual scroll
          vm.itemListLoaded = true;

          // if one supplier is selected the common delivery date is enabled
          if(vm.filter.supplierId && vm.items.length > 0){
            vm.disableCommonDate =  false;
            vm.selectedDate = {
              deliveryDate: (vm.items[0].supplier.noSameDayDelivery)?(angular.copy(new Date(new Date().getTime() + ((vm.items[0].supplier.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(vm.tomorrow)),
              minDate : (vm.items[0].supplier.noSameDayDelivery)?(angular.copy(new Date(new Date().getTime() + ((vm.items[0].supplier.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(new Date(new Date().getTime())))
            }
          }
          vm.saveAdhoc();
        },function(error){
          NotificationService.error({
            title: 'Buy Now - Listing Items',
            error: error
          });
        });
      }


      /**
      * [addToCart add item to cart]
      * @param {[type]} itemToBuy [description]
      */
      function addToCart(itemToBuy) {
        var cartItem = {};
        if (CartService.checkLeadTime(itemToBuy)){
          if (CartService.checkOrderedTime(itemToBuy, vm.filter) && CartService.checkDeliveryQuantity(itemToBuy) && vm.checkBusinessOutletSelection()) {
            cartItem.supplierType = vm.filter.supplierVisibilityType;
            cartItem.deliveryDate = UTCDateService.setCurrentTimeToDate(itemToBuy.deliveryDate);
            cartItem.quantity = itemToBuy.quantity;
            cartItem.specialRequest = itemToBuy.specialRequest ? itemToBuy.specialRequest : null;
            cartItem.supplierId = vm.filter.supplierId ? vm.filter.supplierId: itemToBuy.supplier.id ;
            cartItem.items = {};
            cartItem.buyerBusiness = {};
            cartItem.buyerBusiness.id = vm.filter.businessId;
            if(Auth.hasPermission('accessForGlModule')){
              cartItem.glCodeMappings = {};
              cartItem.glCodeMappings.purchaseOrderTypes = {};
              cartItem.glCodeMappings.purchaseOrderTypes.id = vm.filter.purchaseOrderTypeId;
            }
            cartItem.items.id = itemToBuy.itemId;
            CartService.addToCart(cartItem).
            then(function(responseData) {
              NotificationService.success({
                title: 'global.buttons.addToCart',
                message: 'alertMessage.COMPLETED_SUCCESSFULLY'
              });
              $rootScope.$broadcast('refresh-cart');
            }, function(error) {
              NotificationService.error({
                title: 'global.buttons.addToCart',
                error: error
              });
            });
          }
        }
      }

      /**
      * [removeFromCartConfirmationModal remove from cart]
      * @param  {[type]} cartId [cart item id as param]
      * @param  {[type]} event  [event triggered for opening modal]
      * @return {[type]}        [description]
      */
      function removeFromCartConfirmationModal(cartId, event) {
        // update card Id
        vm.cartId = cartId;
        $mdDialog.show({
          templateUrl: 'app/buyer-modules/buy-now/buy-now-main/templates/common/buyer.buy-now.remove-item-modal.tmpl.html',
          controller: function() {
            return vm;
          },
          controllerAs: 'vm',
          targetEvent: event,
          clickOutsideToClose: false
        });
      }

      /**
      * [cancel for closing the modal]
      * @return {[type]} [description]
      */
      function cancel() {
        $mdDialog.cancel();
      }

      /**
      * [removeFromCart remove item from cart using cart item id]
      * @return {[type]} [description]
      */
      function removeFromCart() {
        CartService.removeFromCart(vm.cartId).
        then(function (responseData) {
          NotificationService.success({
            title: 'global.buttons.removeFromcart',
            message: 'alertMessage.COMPLETED_SUCCESSFULLY'
          });
          $rootScope.$broadcast('refresh-cart');
          vm.cancel();
        }, function (error) {
          NotificationService.error({
            title: 'global.buttons.removeFromcart',
            error: error
          });
        });
      }

      /**
      * [addToFavorites Add an item to favourite]
      * @param {[type]} itemToFav [description]
      */
      function addToFavorites(itemToFav) {
        var favItem = {};
        favItem.items = {'id': itemToFav.itemId};
        BuyerBuyNowService.addToFavorites(favItem).
        then(function(responseData) {
          itemToFav.favoriteItem = true;
          NotificationService.success({
            title: 'global.buttons.addToFavourites',
            message: 'alertMessage.COMPLETED_SUCCESSFULLY'
          });
        }, function(error) {
          NotificationService.error({
            title: 'global.buttons.addToFavourites',
            error: error
          });
        });
      }

      /**
      * [removeFromFavorites Remove an item from favourites]
      * @param  {[type]} itemToRemove [description]
      * @return {[type]}              [description]
      */
      function removeFromFavorites(itemToRemove) {
        BuyerBuyNowService.removeFromFavorites(itemToRemove.itemId).
        then(function(responseData) {
          itemToRemove.favoriteItem = false;
          NotificationService.success({
            title: 'global.buttons.removeFromFavourites',
            message: 'alertMessage.COMPLETED_SUCCESSFULLY'
          });
          vm.loadItems();
        }, function(error) {
          NotificationService.error({
            title: 'global.buttons.removeFromFavourites',
            error: error
          });
        });
      }


      /**
      * [toggleItemLoad Change the item load view type]
      * @return {[type]} [description]
      */
      function toggleItemLoad() {
        vm.isFavoritesView = !vm.isFavoritesView;
        vm.itemListType = vm.itemListType === 'all' ? 'favorites' : 'all';
        vm.resetPaginationQuery();
        vm.items = [];
        vm.loadItems();
      }


      //---------------------------BUY NOW/CART ITEM DATA METHODS end ---------------------------//


      //-------------------------- CART ITEM VALIDATIONS ------------------------//


      /**
      * [checkBusinessOutletSelection validation method used for vlidating whether business outlet is chosen or not]
      * @return {[type]} [description]
      */
      function checkBusinessOutletSelection() {
        if(vm.filter.businessId) {
          return true;
        }
        else {
          NotificationService.simpleErrorToast({
            title: 'alertMessage.ERROR',
            message:'alertMessage.SELECT_BUSINESS_OUTLET'
          });
          return false;
        }
      }

      /**
      * [checkItemInCart check if item is in cart and returns the cart id of the item]
      * @param  {[type]} item [item that is added to the cart]
      * @return {[type]}      [description]
      */
      function checkItemInCart(item) {
        var index =  CartService.checkItemInCart(item);
        if(index !== false) {
          item.inCartId = index;
        }
        return index;
      }

      //-------------------------- CART ITEM VALIDATIONS end------------------------//


      //------------------------------ ORDER TEMPLATE --------------------------------------//

      /**
      * [loadOrderTemplateDetails load order template details]
      * @return {[type]} [description]
      */
      function loadOrderTemplateDetails() {
        vm.recordError=[];
        vm.items = undefined;
        if(vm.filter.templateId && vm.filter.purchaseMethod === 'PURCHASE_BY_TEMPLATE'){
          BuyerBuyNowService.getAllItemBuyerMappingsByTemplateId(vm.filter.templateId, {
            view: vm.itemListType,
            outletId: vm.filter.businessId,
            query: vm.searchText,
            page: vm.query.page - 1,
            size: vm.query.limit,
            sort: sort()
          }).then(function (response) {
            vm.disableCommonDate =  true;
            //item parameters to be set on load
            _.each(response.data.items, function(element, index) {
              _.extend(element,
                {
                  deliveryDate: (element.supplier.noSameDayDelivery)? (angular.copy(new Date(new Date().getTime() + ((element.supplier.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(vm.tomorrow)),
                  quantity: angular.copy(element.inCartQuantity),
                  minDate: (element.supplier.noSameDayDelivery)?(angular.copy(new Date(new Date().getTime() + ((element.supplier.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(new Date(new Date().getTime())))
                })
              });

              vm.items = response.data.items;
              vm.totalItems = vm.items.length;
              vm.orderTemplateDetails = response.data.orderTemplate;
              vm.filter.templateName = response.data.orderTemplate.name; // to be shown in title of the listing panel
              vm.itemListLoaded = true;
              vm.filter.businessId = vm.orderTemplateDetails.buyerBusiness.id;
              vm.filter.businessOutletName = vm.orderTemplateDetails.buyerBusiness.businessOutletName; // to be shown in title of the listing panel
              vm.getTemplatesByOutlet();

              vm.filter.supplierList = BuyerOrderTemplateService.getSelectedSuppliersNames(vm.items);

              // if one supplier is selected the common delivery date is enabled
              if(BuyerOrderTemplateService.checkSupplierLength(vm.items)===1 && vm.items.length > 0){
                vm.disableCommonDate =  false;
                vm.selectedDate = {
                  deliveryDate: (vm.items[0].supplier.noSameDayDelivery)?(angular.copy(new Date(new Date().getTime() + ((vm.items[0].supplier.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(vm.tomorrow)),
                  minDate : (vm.items[0].supplier.noSameDayDelivery)?(angular.copy(new Date(new Date().getTime() + ((vm.items[0].supplier.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(new Date(new Date().getTime())))
                }
              }

              vm.filter.supplierList =_.uniqBy(_.map(vm.items, function(item){
                return {
                  'id': item.supplier.id,
                  'supplierName': item.supplier.companyName,
                  'selected': true
                };
              }), 'id');  //uniqBy is used instead of uniq since lodash map adds a key 'hashkey' and hence uniq cannot be used

              vm.purchaseOrderTypes = vm.orderTemplateDetails.purchaseOrderType ? [vm.orderTemplateDetails.purchaseOrderType] : null;
              vm.filter.purchaseOrderTypeId = vm.purchaseOrderTypes ? vm.purchaseOrderTypes[0].id : null;

            }, function (error) {
              NotificationService.error({
                title: 'Order Templates',
                error: error
              });
            });
          }
        }


        /**
        * [getSelectedTemplateItems get template items for which the user has added the quantity]
        * @return {[type]} [items for the quantity has been added]
        */
        function getSelectedTemplateItems(){
          var cart = _.map(vm.items, function (item) {
            if((item.quantity!=null)){
              return {
                "buyerBusiness": { "id": vm.filter.businessId },
                "glCodeMappings": {"purchaseOrderTypes": {"id": vm.filter.purchaseOrderTypeId}},
                "supplierId": item.supplier.id,
                "supplierType": vm.filter.supplierVisibilityType,
                "items": {'id': item.itemId},
                'quantity': item.quantity,
                'deliveryDate': item.deliveryDate
              }
            }
          });
          var cartItems= _.compact(cart);
          return cartItems;
        }

        /**
        * [addTemplateItemsToCart add template items to cart]
        */
        function addTemplateItemsToCart(){
          var templateItems = vm.getSelectedTemplateItems();
          if(templateItems.length >0){
            CartService.createCartByTemplate(templateItems).then(function (response) {
              if(response.data.responseList.length>0){
                vm.recordError = response.data;
                NotificationService.simpleErrorToast({
                  title: 'global.buttons.addToCart',
                  message: 'alertMessage.SCROLL_UP_TO_SEE_THE_ERROR'
                });
              }
              else{
                NotificationService.success({
                  title: 'global.buttons.addToCart',
                  message: 'alertMessage.COMPLETED_SUCCESSFULLY'
                });
                $rootScope.$broadcast('refresh-cart');
                $state.go('buyer.cart-checkout-mobile', {
                  'purchaseMethod': vm.filter.purchaseMethod,
                  'templateId': vm.filter.templateId
                },{
                  reload: false
                });
              }
            }, function (error) {
              NotificationService.error({
                title: 'global.buttons.addToCart',
                error: error
              });
            });
          }
        }

          //------------------------------ ORDER TEMPLATE end--------------------------------------//


          //------------------------- UNSORTED FUNCTIONS ------------------------------------//
          /**
          * [saveAdhoc save supplier visibility type (adhoc/allocated) to be retrieved at checkout page - may not be used in mobile]
          * @return {[type]} [description]
          */
          function saveAdhoc(){
            sessionStorage.setItem("adhocType", vm.filter.supplierVisibilityType);
          }

          function transition() {
            vm.loadItems();
          }

          //------------------------- UNSORTED FUNCTIONS ------------------------------------//

        }
      })();
