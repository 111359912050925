/**
 * @Author: Ajay
 * @Date:   15-Jan-2018 11:57 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.food-cost-data.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:52 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerFoodCostDataController', BuyerFoodCostDataController);

  BuyerFoodCostDataController.$inject = ['$scope', '$rootScope', '$q', 'toastr', '$filter', '$state', '$stateParams', 'pagingParams', 'UTCDateService', 'BuyerBusinessUserMapService', 'GlSpecificItemGroupService', 'BusinessReportService', 'paginationConstants', 'NotificationService', 'UtilService', 'CSVExportService','CacheMiddlewareService'];

  function BuyerFoodCostDataController($scope, $rootScope,$q ,toastr, $filter, $state, $stateParams, pagingParams, UTCDateService, BuyerBusinessUserMapService, GlSpecificItemGroupService, BusinessReportService, paginationConstants, NotificationService, UtilService, CSVExportService,CacheMiddlewareService) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.loadAll = loadAll;
    vm.filter = {};
    vm.filter.fromDate = new Date();
    vm.filter.toDate = new Date();
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.loadAllGlSpecificItemGroups = loadAllGlSpecificItemGroups;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.getItemGroups = getItemGroups;
    vm.getSupplierColumns = getSupplierColumns;
    vm.consolidateCSV = consolidateCSV;
    vm.validDateFilter = validDateFilter;
    vm.getFoodCostCSVHeader = getFoodCostCSVHeader;
    vm.foodCostCSVData = foodCostCSVData;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.resetFilters = resetFilters;

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
    function getAllBuyerBusinessOutlets() {
      var endpoint = '/api/business-outlets';
      if(vm.businessOutlets)
          return false;
      vm.businessOutlets = null;
      CacheMiddlewareService.getData(endpoint,BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser).then(function(response){
          vm.businessOutlets = response;
      }, function(error){
          console.error('Error fetching business outlets:', error);
      });
  }

    function loadAllGlSpecificItemGroups() {
      if(vm.glGroups)
        return false;
      GlSpecificItemGroupService.getAllGlSpecificItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.glGroups = response.data;
      }, function(error) {
      });
    }

    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets or glGroups]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.buyerBusinessIdList = _.map(vm.businessOutlets, _.property('id'));
        break;
      case 'GLGROUPS':
        vm.filter.glSpecificItemGroupIdList = _.map(vm.glGroups, _.property('id'));
        break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets or glGroups]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.buyerBusinessIdList = [];
        break;
      case 'GLGROUPS':
        vm.filter.glSpecificItemGroupIdList = [];
        break;
      }
    }

    var tablesToExcel = (function () {
      var uri = 'data:application/vnd.ms-excel;base64,'
        , tmplWorkbookXML = '<?xml version="1.0"?><?mso-application progid="Excel.Sheet"?><Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">'
          + '<DocumentProperties xmlns="urn:schemas-microsoft-com:office:office"><Author>Munaf Saiyed</Author><Created>{created}</Created></DocumentProperties>'
          + '<Styles>'
          + '<Style ss:ID="Currency"><NumberFormat ss:Format="Currency"></NumberFormat></Style>'
          + '<Style ss:ID="Date"><NumberFormat ss:Format="Medium Date"></NumberFormat></Style>'
          + '</Styles>'
          + '{worksheets}</Workbook>'
        , tmplWorksheetXML = '<Worksheet ss:Name="{nameWS}"><Table>{rows}</Table></Worksheet>'
        , tmplCellXML = '<Cell{attributeStyleID}{attributeFormula}><Data ss:Type="{nameType}">{data}</Data></Cell>'
        , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
        , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
      return function (tables, wsnames, wbname, appname) {
        var ctx = "";
        var workbookXML = "";
        var worksheetsXML = "";
        var rowsXML = "";
        for (var i = 0; i < wsnames.length; i++) {
          tables[wsnames[i]]['Date'] = vm.days;
          var tmp = Object.keys(tables[wsnames[i]]);
          tmp.sort(function (a, b) {
            if (a < b) { return -1; }
            if (a > b) { return 1; }
            return 0;
          });

          const itemsToRemove = ['Date', 'Total'];

          var filteredArray = _.filter(tmp, function(item) {
            return !_.includes(itemsToRemove, item);
          });

          filteredArray.unshift('Date');
          var cellsExl = filteredArray;
          cellsExl.push('Total','Currency');
          rowsXML += '<Row>'
          for (var k = 0; k < cellsExl.length; k++) {
            var dataTypeH = null;
            var dataStyleH = null;
            var dataValueH = cellsExl[k];
            var dataFormulaH = null;
            ctx = {
              attributeStyleID: (dataStyleH == 'Currency' || dataStyleH == 'Date') ? ' ss:StyleID="' + dataStyleH + '"' : ''
              , nameType: (dataTypeH == 'Number' || dataTypeH == 'DateTime' || dataTypeH == 'Boolean' || dataTypeH == 'Error') ? dataTypeH : 'String'
              , data: (dataFormulaH) ? '' : dataValueH
              , attributeFormula: (dataFormulaH) ? ' ss:Formula="' + dataFormulaH + '"' : ''
            };
            rowsXML += format(tmplCellXML, ctx);
          }
          rowsXML += '</Row>';
          for (var j = 0; j < vm.days.length; j++) {
            rowsXML += '<Row>'
            var dataType = null;
            var dataStyle = null;
            var dataFormula = null;
            var dataValue = vm.days[j];
            ctx = {
              attributeStyleID: (dataStyle == 'Currency' || dataStyle == 'Date') ? ' ss:StyleID="' + dataStyle + '"' : ''
              , nameType: (dataType == 'Number' || dataType == 'DateTime' || dataType == 'Boolean' || dataType == 'Error') ? dataType : 'String'
              , data: (dataFormula) ? '' : dataValue
              , attributeFormula: (dataFormula) ? ' ss:Formula="' + dataFormula + '"' : ''
            };
            rowsXML += format(tmplCellXML, ctx);
            for (var k = 0; k < cellsExl.length; k++) {
              var dataType = null;
              var dataStyle = null;
              var dataFormula = null;
              var dataValue = "0.00";
              if (cellsExl[k] !== "Date" && cellsExl[k] !== 'Currency') {
                const obj = tables[wsnames[i]][cellsExl[k]];
                const keys = Object.keys(obj);
                for (var l = 0; l < keys.length; l++) {
                  const key = keys[l];
                  if (vm.days[j] === key) {
                    dataValue = obj[key] ? $filter('decimalNumber')(obj[key], 2) : 0;
                  }
                } 
                ctx = {
                  attributeStyleID: (dataStyle == 'Currency' || dataStyle == 'Date') ? ' ss:StyleID="' + dataStyle + '"' : ''
                  , nameType: (dataType == 'Number' || dataType == 'DateTime' || dataType == 'Boolean' || dataType == 'Error') ? dataType : 'String'
                  , data: (dataFormula) ? '' : dataValue
                  , attributeFormula: (dataFormula) ? ' ss:Formula="' + dataFormula + '"' : ''
                };
                rowsXML += format(tmplCellXML, ctx);
              }

              if(cellsExl[k] === 'Currency'){
                dataValue = 'SGD';
                ctx = {
                  attributeStyleID: (dataStyle == 'Currency' || dataStyle == 'Date') ? ' ss:StyleID="' + dataStyle + '"' : ''
                  , nameType: (dataType == 'Number' || dataType == 'DateTime' || dataType == 'Boolean' || dataType == 'Error') ? dataType : 'String'
                  , data: (dataFormula) ? '' : dataValue
                  , attributeFormula: (dataFormula) ? ' ss:Formula="' + dataFormula + '"' : ''
                };
                rowsXML += format(tmplCellXML, ctx);
              }
            }
            rowsXML += '</Row>'
          }
          ctx = { rows: rowsXML, nameWS: wsnames[i] || 'Sheet' + i };
          worksheetsXML += format(tmplWorksheetXML, ctx);
          rowsXML = "";
        }

        ctx = { created: (new Date()).getTime(), worksheets: worksheetsXML };
        workbookXML = format(tmplWorkbookXML, ctx);
        var link = document.createElement("A");
        link.href = uri + base64(workbookXML);
        link.download = wbname || 'Workbook.xls';
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        vm.loadAll();
      }
    })();

    function consolidateCSV(outlet, itemGroup) {
        var tablesExl = Object.keys(vm.foodCostData[outlet]);
        tablesToExcel(vm.foodCostData[outlet],tablesExl, 'Food_Cost_Data_' + outlet + '_' + itemGroup + '_Consolidate.xls', 'Excel');
    }

    function loadAll() {
      if(!vm.validDateFilter()){
        return false;
      }
      vm.days = UtilService.generateDays(vm.filter.fromDate, vm.filter.toDate);
      BusinessReportService.getFoodCostData({
        buyerBusinessIdList: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList,
        glSpecificItemGroupIdList: !vm.filter.glSpecificItemGroupIdList ? [] : vm.filter.glSpecificItemGroupIdList,
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        query: !vm.filter.itemName ? '' : vm.filter.itemName
      }, {
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
      }).then(function(response){
        vm.foodCostData = response.data;
        vm.outlets = Object.keys(vm.foodCostData);
        vm.selectedDateRange = {
          fromDate: vm.filter.fromDate,
          toDate: vm.filter.toDate
        };
      },function(error){
        NotificationService.error({
          title: 'Fetching Good Received Data',
          error: error
        });
      });
    }

    /**
     * [getItemGroups description]
     * @param  {[type]} outlet [description]
     * @return {[type]}           [description]
     */
    function getItemGroups(outlet) {
      var itemGroups = Object.keys(vm.foodCostData[outlet]);
      var sortedItemGroups = _.orderBy(itemGroups, [], ['asc']);
      return sortedItemGroups;
    }

    /**
     * [getSupplierColumns description]
     * @param  {[type]} itemGroup [description]
     * @return {[type]}           [description]
     */
    function getSupplierColumns(outlet, itemGroup) {
      var columnKeys = Object.keys(vm.foodCostData[outlet][itemGroup]);
      var sortedColumnKeys = _.orderBy(columnKeys, [], ['asc']);
      return UtilService.swapArrayValueToEnd(sortedColumnKeys, "Total");
    }

    /**
     * [validDateFilter description]
     * @return {[type]} [description]
     */
    function validDateFilter() {
      var validSelection = true;
      var sameMonth = moment(vm.filter.fromDate).isSame(vm.filter.toDate, 'month');
      var sameYear = moment(vm.filter.fromDate).isSame(vm.filter.toDate, 'year');
      if(moment(vm.filter.toDate).isBefore(vm.filter.fromDate)){
        validSelection = false;
        toastr.error('Please select valid date range!', 'Food Cost Data');
      }
      if(sameMonth  === false || sameYear === false){
        validSelection = false;
        toastr.error('Please choose dates from same month!', 'Food Cost Data');
      }
      return validSelection;
    }

    function getFoodCostCSVHeader(outlet, itemGroup) {
      var csvHeaders = vm.getSupplierColumns(outlet, itemGroup);
      csvHeaders.unshift("Date");
      csvHeaders.push("Currency");
      return csvHeaders;
    }

    function foodCostCSVData(outlet, itemGroup) {
      var deferred = $q.defer();
      if (!vm.foodCostData) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      vm.foodCostCSVHeader = vm.getFoodCostCSVHeader(outlet, itemGroup);
      var metaData = {
        outlet: outlet,
        itemGroup: itemGroup,
        rows: UtilService.generateDays(vm.filter.fromDate, vm.filter.toDate),
        columns: vm.foodCostCSVHeader
      };
      return CSVExportService.getFoodCostCSVData(vm.foodCostData, metaData);
    }

    function resetFilters() {
        vm.filter = {}
        vm.filter.fromDate = new Date();
        vm.filter.toDate = new Date();
        vm.foodCostData = null;
        vm.outlets = null;
    }

  }
})();
