/**
 * @Author: Ajay
 * @Date:   23-Feb-2018 16:35 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.item-pricing.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:53 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerItemPricingController', BuyerItemPricingController);

  BuyerItemPricingController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'NotificationService', '$mdDialog','pagingParams', 'BuyerDataService', 'UTCDateService', 'BuyerBusinessService','BusinessReportService', 'BrandManagementService', 'BuyerSupplierMappingService', 'ItemGroupService', 'MainItemGroupService', 'GlSpecificItemGroupService', 'paginationConstants', 'BuyerBusinessUserMapService', 'BuyerBuyNowService', 'CSVExportService', 'EXPORT_CONSTANTS','CacheMiddlewareService'];

  function BuyerItemPricingController($scope, $rootScope,$q ,$filter, $state, $stateParams, NotificationService, $mdDialog, pagingParams, BuyerDataService, UTCDateService, BuyerBusinessService, BusinessReportService, BrandManagementService, BuyerSupplierMappingService, ItemGroupService, MainItemGroupService, GlSpecificItemGroupService, paginationConstants, BuyerBusinessUserMapService, BuyerBuyNowService, CSVExportService, EXPORT_CONSTANTS,CacheMiddlewareService) {
    var vm = this;
    vm.predicate = JSON.parse(localStorage.getItem("report_item_price_predicate")) || pagingParams.predicate;
    vm.reverse = JSON.parse(localStorage.getItem("report_item_price_reverse")) || pagingParams.ascending;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.loadMainItemGroups = loadMainItemGroups;
    vm.getAllGroupedItemGroups = getAllGroupedItemGroups;
    vm.loadAll = loadAll;
    vm.mainItemGroupSelectionChanged = mainItemGroupSelectionChanged;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.todaysDate = new Date();
    vm.loadAllGlSpecificItemGroups = loadAllGlSpecificItemGroups;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.Items = {
      businessOutlets: [],
      prices: [],
      uniqPrice: null
    };
    vm.pagination = pagination;

    vm.limitOptions = paginationConstants.limitOptionsWithAll;
    //CSV Header
    vm.ItemPricingHeader = EXPORT_CONSTANTS.CSV.ItemPricingReport;
    vm.changeBuyerBusinessOutlet = changeBuyerBusinessOutlet;
    //CSV Function Declaration
    vm.getItemPricingCSVData = getItemPricingCSVData;
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
    vm.getAllBrandMappedBusinessOutlets = getAllBrandMappedBusinessOutlets;
    vm.filter = {
      businessOutletIdList: [],
      mainItemGroupIdList: [],
      itemGroupIdList: [],
      supplierIdList: []
    };
    vm.resetFilters = resetFilters;
    var priceItemsList = {};
    vm.init = init();
    //Getting CSV DATA
    function getItemPricingCSVData(){
        vm.poItems = _.uniqBy(vm.poItems, 'itemId');
        var deferred = $q.defer();
      if (!vm.poItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }

      return CSVExportService.getitemPricingCSV(vm.poItems);
    }

    function init() {
      var itemList = localStorage.getItem("report_item_price_filter");
      if (itemList) {
        priceItemsList = JSON.parse(itemList);
        if (priceItemsList.brandIdList) {
          getAllBrandsOfBuyerList();
        }
        if (priceItemsList.supplierIdList) {
          getAllAllocatedSuppliersForBusiness();
        }
        if (priceItemsList.itemGroupIdList) {
          getAllGroupedItemGroups(vm.filter.mainItemGroupIdList);
        }
        if (priceItemsList.buyerBusinessIdList) {
          getAllBrandMappedBusinessOutlets(vm.filter.brandIdList)
        }
        if (priceItemsList.glSpecificItemGroupIdList) {
          loadAllGlSpecificItemGroups();
        }
        vm.filter = priceItemsList;
        loadAll();
      }
    }


    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

     /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList(){
      if(vm.brands)
        return false;
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query:'',
        buyerId : vm.buyerId
      };
      BrandManagementService.getAllBrandsOfBuyerList(params)
      .then(function(response){
        vm.brands = response;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    function changeBuyerBusinessOutlet(){
      vm.filter.buyerBusinessIdList=[];
    }
    /**
    * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
    function getAllBuyerBusinessOutlets() {
      var endpoint = '/api/business-outlets';
      if(vm.businessOutlets)
          return false;
      vm.businessOutlets = null;
      CacheMiddlewareService.getData(endpoint,BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser).then(function(response){
          vm.businessOutlets = response;
      }, function(error){
          console.error('Error fetching business outlets:', error);
      });
  }

    /**
    * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
    */
    function getAllAllocatedSuppliersForBusiness() {
      if(vm.supplierList)
        return false;
      var params = {'businessId': ''};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
      },function(error){
      });
    }

    /**
    * [loadMainItemGroups Gets all Main Item Groups]
    */
    function loadMainItemGroups() {
      if (vm.mainItemGroups){
        return false;
      }
      MainItemGroupService.getAllMainItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.mainItemGroups = response.data;
      }, function(error) {
      });
    }

    /**
    * [getAllGroupedItemGroups Gets all ItemGroups Grouped by Main Item Groups]
    */
   function getAllGroupedItemGroups(mainItemGroupIdList) {
    if (vm.itemGroups && !vm.filter.mainItemGroupChanged){
      return false;
    }
    vm.filter.mainItemGroupChanged = false;
    ItemGroupService.getAllGroupedItemGroups(mainItemGroupIdList, {
      page: 0,
      size: paginationConstants.maxLimit,
      query: ''
    }).then(function(response) {
      vm.itemGroups = response.data;
    }, function(error) {
    });
  }

    function loadAllGlSpecificItemGroups() {
      if(vm.glGroups)
        return false;
      GlSpecificItemGroupService.getAllGlSpecificItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.glGroups = response.data;
      }, function(error) {
      });
    }

    function mainItemGroupSelectionChanged() {
      vm.filter.mainItemGroupChanged = true;
      vm.filter.itemGroupIdList = [];
    }

    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups, itemGroups or glGroups]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.buyerBusinessIdList = _.map(vm.businessOutlets, _.property('id'));
        break;
      case 'SUPPLIERS':
        vm.filter.supplierIdList = _.map(vm.supplierList, _.property('id'));
        break;
      case 'MAIN_ITEMGROUPS':
        vm.mainItemGroupSelectionChanged();
        vm.filter.mainItemGroupIdList = _.map(vm.mainItemGroups, _.property('id'));
        break;
      case 'ITEMGROUPS':
        vm.filter.itemGroupIdList = _.map(_.flatten(_.without(_.map(vm.itemGroups, _.property('itemGroupDTOList')), undefined, null)), _.property('itemGroupId'));
        break;
      case 'GLGROUPS':
        vm.filter.glSpecificItemGroupIdList = _.map(vm.glGroups, _.property('id'));
        break;
      case 'BRANDS':
        vm.filter.brandIdList = _.map(vm.brands, _.property('id'));
        break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups, itemGroups or glGroups]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.buyerBusinessIdList = [];
        break;
      case 'SUPPLIERS':
        vm.filter.supplierIdList = [];
        break;
      case 'MAIN_ITEMGROUPS':
        vm.filter.mainItemGroupIdList = [];
        vm.mainItemGroupSelectionChanged();
        break;
      case 'ITEMGROUPS':
        vm.filter.itemGroupIdList = [];
        break;
      case 'GLGROUPS':
        vm.filter.glSpecificItemGroupIdList = [];
        break;
      case 'BRANDS':
        vm.filter.brandIdList = [];
        vm.filter.buyerBusinessIdList = [];
        break;
      }
    }

    function loadAll() {
      localStorage.setItem('report_item_price_filter',JSON.stringify(vm.filter))
      localStorage.setItem('report_item_price_predicate',JSON.stringify(vm.predicate));
      localStorage.setItem('report_item_price_reverse',JSON.stringify(vm.reverse));

      BusinessReportService.getPriceListingReport({
        brandIdList: !vm.filter.brandIdList ? [] : vm.filter.brandIdList,
        buyerBusinessIdList: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList ,
        supplierIdList: !vm.filter.supplierIdList ? []: vm.filter.supplierIdList,
        mainItemGroupIdList: !vm.filter.mainItemGroupIdList ? []: vm.filter.mainItemGroupIdList,
        itemGroupIdList: !vm.filter.itemGroupIdList ? [] :vm.filter.itemGroupIdList,
        glSpecificItemGroupIdList: !vm.filter.glSpecificItemGroupIdList ? [] : vm.filter.glSpecificItemGroupIdList,
        query: !vm.filter.itemName ? '' : vm.filter.itemName,
        buyerItemCode: !vm.filter.buyerItemCode?'':vm.filter.buyerItemCode
      },{
        page: 0,
        size:paginationConstants.maxLimit,
        sort: sort()
      }).then(function(response){
        vm.poItems = response.data;
        vm.Items = _.uniqBy(vm.poItems, 'itemId');
        vm.Items = _.forEach(vm.Items, function(each){
            return each.prices = [] , each.uniqPrice = null ;
          });
        // grouping mulitple outlets and prices for each item
        _.forEach(vm.Items, function(item){
          _.forEach(vm.poItems, function(each){
              if(item.itemId == each.itemId){
                item.businessOutlets.push(each.businessOutletName);
                item.prices.push(each.price);
              }
          });
        });
        vm.Items = _.forEach(vm.Items, function(item){
          var priceArr =_.uniqBy(item.prices);
          if(priceArr.length == 1){
            return item.uniqPrice = item.prices[0];
          }
          return item;
        });
        vm.totalItems = vm.Items.length;
        vm.priceList = _.slice(vm.Items, (vm.query.limit*(vm.query.page - 1)), (vm.query.limit*(vm.query.page - 1))+vm.query.limit);
      },function(error){
      });
    }

     /**
     * pagination
     */
    function pagination(){
      vm.priceList = [];
      vm.priceList = _.slice(vm.Items, (vm.query.limit*(vm.query.page - 1)), (vm.query.limit*(vm.query.page - 1))+vm.query.limit);
    }

    /**
     * getAllBrandMappedsBusinessOutlets gets all BO of selected brand and buyer
     * @param {List} brandIdList
     */
    function getAllBrandMappedBusinessOutlets(brandIdList) {
      if (!brandIdList || brandIdList.length === 0){
        vm.getAllBuyerBusinessOutlets();
        return false;
      }
      var itemTerminationDTO = {
        'brandIdList': brandIdList,
        'buyerIdList': [vm.buyerId]
      }
      var params = {
        page: 0,
        size: paginationConstants.maxLimit
      }
      BuyerBusinessService.getAllBrandMappedsBusinessOutlets(itemTerminationDTO, params).then(function(response) {
        vm.businessOutlets  = response.data;
      }, function(error) {
      });
    }

    function resetFilters() {
      vm.filter = {
        businessOutletIdList: [],
        mainItemGroupIdList: [],
        itemGroupIdList: [],
        supplierIdList: []
      }; 
      vm.filter.glSpecificItemGroupIdList = [];
      vm.filter.itemName = '';
      localStorage.removeItem("report_item_price_filter");
      localStorage.removeItem("report_item_price_predicate");
      localStorage.removeItem("report_item_price_reverse");
      vm.poItems = null;
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
    };
    }

  }
})();
