(function () {
    'use strict';
    angular
      .module('ezyprocureApp')
      .controller('WebReminderController', WebReminderController);
    WebReminderController.$inject = ['Auth', '$mdDialog','$localStorage','data','BuyerDataService', 'SubscriptionPlan','NotificationService'];
    function WebReminderController(Auth, $mdDialog, $localStorage, data, BuyerDataService, SubscriptionPlan, NotificationService) {
      var vm = this;
      vm.getSubscriptionDetails = getSubscriptionDetails;
      vm.closeDialog = closeDialog;
      vm.licenseExpiryFlag = false;
      vm.sendEmail = sendEmail;
      vm.getOutletNames = getOutletNames;
      vm.areDatesEqual = areDatesEqual;
      vm.logout = logout;
      vm.todaysDate = new Date();
      vm.countOutlets = 0;
      vm.userRole = Auth.getUserRole().ugly;

      function getSubscriptionDetails() {
        const date = new Date(data.valid_to);
        // Extract date parts
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based
        const year = date.getFullYear();
        vm.providedDate = day +'/' + ( month ) +'/' + year;
        vm.minDaysRemaining =data.minDaysRemaining;
        vm.expiringSubscriptions = data.expiringSubscriptions;
        if(vm.userRole === 'ROLE_BUYER'){
          vm.subscriptionExpiringIn45Days = data.subscriptionExpiringIn45Days.sort(function(a, b) {
            return new Date(a.subscriptionEndDate) - new Date(b.subscriptionEndDate);
          });
        }
      
        // Checking how many outlets are expired out of the total outlets in 45days
        _.map(data.expiringSubscriptions, function (subscription) {                    
          var date1 = new Date(subscription.subscriptionEndDate);
          if (subscription.subscriptionEndDate !== null && date1.getTime() <= vm.todaysDate.getTime()) {
            vm.countOutlets += 1;
          }
          return {
            businessOutletName: vm.userRole === 'ROLE_BUYER'?subscription.businessOutletName:''
          };
        });
        if(vm.minDaysRemaining < 1){
          vm.licenseExpiryFlag = true;
          if(vm.countOutlets === vm.expiringSubscriptions.length){
          sessionStorage.setItem('licenseExpirationData', JSON.stringify(data));
          }
        }
        
        vm.businessOutlets = _.map(data.minDaysSubscriptions, function (subscription) {
          return {
            businessOutletName: vm.userRole === 'ROLE_BUYER'?subscription.businessOutletName:''
          };
        });
      }

      function closeDialog() {
        $mdDialog.hide();
      }

      function sendEmail() {
        // Extract companyName and brandName from each subscription
        var emailDTO = _.reduce(vm.expiringSubscriptions, function (result, subscription) {
          var date1 = new Date(subscription.subscriptionEndDate);        
          if (date1.getTime() <= vm.todaysDate.getTime()) {
            var emailData = {
              companyName: subscription.companyName
            };        
            if (vm.userRole === 'ROLE_BUYER') {
              emailData.businessOutletName = subscription.businessOutletName;
            }        
            result.push(emailData); // Only push valid objects into the result array
          }        
          return result;
        }, []);        
        SubscriptionPlan.sendEmailToCS(emailDTO).then(function(response) {
          if(response.data.status === "Success"){
            NotificationService.success({
              title: 'License Expiry',
              message: 'Email sent successfully!'
            });
          }
         }, function(error) {
           NotificationService.error({
             title: 'License Expiry',
             error: error
           });
         });
       }

      // To display outlet names in popup
      function getOutletNames(subscriptions) {
        if (subscriptions && subscriptions.length) {
          return _.map(subscriptions,function (outlet) {
            return outlet.businessOutletName;
          }).join(', ');
        }
        return '';
      };

      function areDatesEqual(date1) {
        var date2 = new Date(data.valid_to);
        date1 = new Date(date1);
        date1.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);
        vm.todaysDate.setHours(0, 0, 0, 0);
        if(date1.getTime() <= vm.todaysDate.getTime()){
          return true;
        }
        return false;
      }

      function logout() {
        sessionStorage.clear();
        vm.closeDialog();
        Auth.logout();
      }

    }
  })();