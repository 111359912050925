/**
* @Author: Elsa
* @Date:   2017-05-27T17:27:52+05:30
* @Project: Ezyprocure
* @Filename: supplier.credit-note.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-01-22T12:31:24+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierCreditNoteReportController', SupplierCreditNoteReportController);

  SupplierCreditNoteReportController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'pagingParams', 'toastr', '$mdDialog', 'UTCDateService', 'NotificationService', 'PurchaseOrderService', 'ItemGroupService', 'GLOBAL_CONSTANTS', 'paginationConstants','CSVExportService','EXPORT_CONSTANTS', 'SupplierDataService', 'BuyerSupplierMappingService','CacheMiddlewareService'];

  function SupplierCreditNoteReportController($scope, $rootScope, $q, $filter, $state, $stateParams, pagingParams, toastr, $mdDialog, UTCDateService, NotificationService, PurchaseOrderService, ItemGroupService, GLOBAL_CONSTANTS, paginationConstants, CSVExportService, EXPORT_CONSTANTS, SupplierDataService, BuyerSupplierMappingService,CacheMiddlewareService) {
    var vm = this;
    vm.loadItemGroups=loadItemGroups;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };

    vm.creditNoteTypes = GLOBAL_CONSTANTS.CREDIT_NOTE_TYPES;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.limitOptions = paginationConstants.limitOptions;
    //Header
    vm.creditNoteCSVReport = EXPORT_CONSTANTS.CSV.SupplierCreditNoteCSVReport;
    //Function Declaration
    vm.getCreditNote = getCreditNote;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.filter = {
      buyerIdList : []
    };
    vm.resetFilters = resetFilters;

     //Get all buyers mapped to the supplier
     function getAllSupplierMappedBuyers() {
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if (vm.buyers)
        return false;
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function (response) {
        vm.buyers = response.data;
      }, function (error) {
      });
    }

    //Getting CSV DATA
    function getCreditNote(){
      var deferred = $q.defer();
      if (!vm.creditNoteItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getSupplierCreditNoteReportCSV(vm.creditNoteItems);
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadItemGroups() {
      var endpoint = '/api/item-groups';
      var param={page: 0,size: paginationConstants.maxLimit,query: ''};
      if (vm.itemGroups)
          return false;
      vm.itemGroups = null;
      CacheMiddlewareService.getData(endpoint,ItemGroupService.getAllItemGroupsData,param).
          then(function (response) {
            vm.itemGroups = response;
          }, function (error) {
          console.error('Error fetching item Groups:', error);
          });
  }
    /**
    * [loadAll Get all Purchase Order Details]
    */
    function loadAll() {
      getIndividualPODetails();
    }

    /**
    * [getIndividualPODetails  gets PO details for each PO Number]
    */
    function getIndividualPODetails() {
      PurchaseOrderService.getAllPurchaseOrders({
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        purchaseOrderStatus: '',
        invoiceStatus: '',
        invoiceNumber: '',
        businessOutletId: '',
        businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
        //If dates are selected, they are converted to UTC format
        poFromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        poToDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        deliveryFromDate: '',
        deliveryToDate: '',
        grnFromDate : '',
        grnToDate : '',
        query: !vm.filter.itemName ? '' : vm.filter.itemName,
        supplierId: '',
        itemGroupId: !vm.filter.itemGroupId ? '' : vm.filter.itemGroupId,
        buyerIdList: !vm.buyerIdList ? '': vm.buyerIdList,
        debitNoteStatus: '',
        grnNumber: '',
        buyerName: '',
        creditNoteStatus: !vm.filter.creditNoteStatus ? '' : vm.filter.creditNoteStatus,
        supplierVisibilityType: '',
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        xeroExported: '',
        module : 0
      }).then(function(response){
        vm.creditNoteItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
        NotificationService.error({
          title: 'Purchase Order Details',
          error: error
        });
      });
    }

    function resetFilters() {
        vm.filter = {};
        vm.filter.creditNoteStatus = "CREDIT_NOTE";
        vm.filter.fromDate = vm.todaysDate;
        vm.filter.toDate = vm.todaysDate;
        vm.itemGroupId = null;
        vm.buyerIdList = [];
        vm.creditNoteItems = [];
    }

  }
})();
