/**
 * @Author: Abhay
 * @Date:   2020-06-09T17:59:19+05:30
 * @Project: Ezyprocure
 * @Filename: buyer.demand-forecast.controller.js
 * @Last modified by:   Abhay
 * @Last modified time: 06-June-2020 18:04 +05:30
 */


(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .controller('BuyerDemandForecastControllerv2', BuyerDemandForecastControllerv2);

    BuyerDemandForecastControllerv2.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'toastr', 'pagingParams', 'BuyerDataService', 'UTCDateService', 'BusinessReportService', 'paginationConstants', 'ReportService', 'CSVExportService', 'EXPORT_CONSTANTS', 'BuyerBusinessUserMapService', 'GLOBAL_CONSTANTS', 'NotificationService','CacheMiddlewareService'];

    function BuyerDemandForecastControllerv2($scope, $rootScope, $q, $filter, $state, $stateParams, toastr, pagingParams, BuyerDataService, UTCDateService, BusinessReportService, paginationConstants, ReportService, CSVExportService, EXPORT_CONSTANTS, BuyerBusinessUserMapService, GLOBAL_CONSTANTS, NotificationService,CacheMiddlewareService) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.todaysDate = new Date();
        var year = vm.todaysDate.getFullYear();
        var month = vm.todaysDate.getMonth();
        var day = vm.todaysDate.getDate();
        vm.toDatedefault = new Date(year, month + 3, day - 1);
        vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
        vm.loadAll = loadAll;
        vm.getAllSuppliers = getAllSuppliers;
        vm.query = {
            page: 1
        };
        vm.filter = {
            buyerBusinessIdList: [],
            supplierIdList: []
        };
        vm.selectAll = selectAll;
        vm.deselectAll = deselectAll;
        vm.getForecastCSVData = getForecastCSVData;
        vm.getForecastCSVHeaderMonthly = EXPORT_CONSTANTS.CSV.ForecastMonthy;
        vm.getForecastCSVHeaderQuarter = EXPORT_CONSTANTS.CSV.ForecastQuarter;
        vm.validDateFilter = validDateFilter;
        vm.getMonths = getMonths;
        vm.getOutlets = getOutlets;
        vm.getLineChart = getLineChart;
        vm.sortByMonth = sortByMonth;
        vm.reportViewTypes = GLOBAL_CONSTANTS.PURCHASE_TREND_VIEW_TYPES;
        vm.selected = 'Month';
        vm.getQuarterSum = getQuarterSum;
        vm.getMonthInSelectedOrder = getMonthInSelectedOrder;
        vm.getYears = getYears;
        vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
        vm.budgetForecast = budgetForecast;
        vm.forecastBudgetMonth = forecastBudgetMonth;
        vm.forecastBudgetQuarter = forecastBudgetQuarter;
        vm.graphDataGenerator = graphDataGenerator;
        vm.getQuarterIndex = getQuarterIndex;
        vm.getItemName = getItemName;
        var limitMonth = moment(vm.toDate).month();
        limitMonth = limitMonth + 2;
        vm.xAxis = [];
        vm.toDateSetonChange = toDateSetonChange;
        vm.fromDateChangeAPI    = new Date(year, month - 3, day - 1);
        vm.toDateChangeAPI      = new Date(year, month + 3, day - 1);
        vm.resetFilters = resetFilters;
        /**
        * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
        */
        function getAllBuyerBusinessOutlets() {
            var endpoint = '/api/business-outlets';
            if(vm.businessOutlets)
                return false;
            vm.businessOutlets = null;
            CacheMiddlewareService.getData(endpoint,BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser).then(function(response){
                vm.businessOutlets = response;
                //if only one business outlet set it as default
                if (vm.businessOutlets.length === 1) {
                    vm.businessId = vm.businessOutlets[0].id;
                }
            }, function(error){
                console.error('Error fetching business outlets:', error);
            });
        }

        function getItemName() {
            if (vm.itemName) {
                return false;
            }
            var params = {
                buyerId: BuyerDataService.getBuyerProfile().buyerUser.buyer.id,
                supplierIdList: vm.filter.supplierIdList ? vm.filter.supplierIdList : '',
                query: '',
                page: 0,
                size: 1000
            };
            BusinessReportService.itemNameService(params).then(function (response) {
                vm.items = response.data;
            },
                function (error) { });
        }

        /**
        * [getTopPurchaseItemsCSVData Gets CSV data]
        */
        function getForecastCSVData() {
            var deferred = $q.defer();
            if (!vm.purchaseTrendItems) {
                NotificationService.simpleErrorToast({
                    title: 'global.table.noRecordsFound',
                    message: 'alertMessage.SEARCH_AND_SUBMIT'
                });
                return deferred.promise;
            }
            var dateRange = vm.fromDate.getDate() + '/' + (vm.fromDate.getMonth() + 1) + '/' + vm.fromDate.getFullYear() + '-' + vm.toDate.getDate() + '/' + (vm.toDate.getMonth() + 1) + '/' + vm.toDate.getFullYear();
            return CSVExportService.getForecastCSV(vm.purchaseTrendItems, dateRange, vm.selected);
        }

        /**
        * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
        */
        function getAllSuppliers() {
            if (vm.supplierList) {
                return false;
            }
            ReportService.getAllSuppliers({
                mainItemGroupIdList: [],
                itemGroupIdList: []
            }, {
                page: 0,
                size: paginationConstants.maxLimit
            }).then(function (response) {
                vm.supplierList = response.data;
            }, function (error) {
            });
        }

        /**
        * [selectAll Selects all from the list]
        * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups, itemGroups or glGroups]
        */
        function selectAll(dropdown) {
            switch (dropdown) {
                case 'OUTLETS':
                    vm.filter.buyerBusinessIdList = _.map(vm.businessOutlets, _.property('id'));
                    break;
                case 'SUPPLIERS':
                    vm.filter.supplierIdList = _.map(vm.supplierList, _.property('id'));
                    break;
            }
        }

        /**
        * [deselectAll Deselect all from the list]
        * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups, itemGroups or glGroups]
        */
        function deselectAll(dropdown) {
            switch (dropdown) {
                case 'OUTLETS':
                    vm.filter.buyerBusinessIdList = [];
                    break;
                case 'SUPPLIERS':
                    vm.filter.supplierIdList = [];
                    break;
            }
        }

        /**
         * [validDateFilter description]
         * @return {[type]} [description]
         */
        function validDateFilter() {

            return true;
        }
        /**
         * [getMonths- Gets the months]
         */
        function getMonths() {
            vm.getMonthInSelectedOrder();
            vm.monthNames = [];
            for (var i = 0; i < vm.outlets.length; i++) {
                _.map(vm.monthArray, function (month) {
                    vm.monthNames.push(month);
                });
            }
        }

        /**
         * [getOutlets - Gets the outlets]
         */
        function getOutlets() {
            vm.outletnames = [];
            _.map(vm.outlets, function (outlet) {
                _.map(_.times(vm.limit, function () { return (outlet); }), function (each) {
                    vm.outletnames.push(each);
                });
            });
        }

        /**
         * [getMonthInSelectedOrder Arranges the month selected order]
         */
        function getMonthInSelectedOrder() {
            vm.monthArray = [];
            var currentMonth = moment(vm.fromDate).month();
            var fromdate = moment(vm.fromDate).month();
            var todate = moment(vm.toDate).month();
            var fromdateYear = moment(vm.fromDate).year();
            var todateYear = moment(vm.toDate).year();
            (fromdate === todate) ? vm.limit = 13 : vm.limit = 12;
            (fromdateYear === todateYear) ? vm.limit += 0 : vm.limit += 2;
            for (var i = 0; i < vm.limit; i++) {
                if (currentMonth < 12) {
                    currentMonth = currentMonth;
                }
                else {
                    currentMonth = 0;
                }
                vm.monthArray.push(vm.months[currentMonth]);
                currentMonth += 1;
            }
        }

        /**
         * [getLineChart -Draws the line chart]
         */
        function getLineChart() {
            var yAxis = [];
            var quarterYears = [];
            vm.getMonthInSelectedOrder();
            _.map(_.chunk(vm.monthArray, 3), function (arr) {
                for (var j = 0; j < arr.length; j++) {
                    if (arr[j] === 'January' || arr[j] === 'March') {
                        if (_.indexOf(yAxis, "Q1") === -1) { yAxis.push("Q1"); }
                    } else if (arr[j] === 'April' || arr[j] === 'June') {
                        if (_.indexOf(yAxis, "Q2") === -1) { yAxis.push("Q2"); }
                    } else if (arr[j] === 'July' || arr[j] === 'September') {
                        if (_.indexOf(yAxis, "Q3") === -1) { yAxis.push("Q3"); }
                    } else if (arr[j] === 'October' || arr[j] === 'December') {
                        if (_.indexOf(yAxis, "Q4") === -1) { yAxis.push("Q4"); }
                    }
                    quarterYears.push(vm.selectedYears[_.indexOf(vm.monthArray, arr[0])]);
                }
            });
            var temp = [];
            for (var k = 0; k < yAxis.length; k++) {
                temp.push(parseInt(yAxis[k].split("")[1]));
            }
            var yr = [];
            _.map(_.chunk(quarterYears, 3), function (arr, i) {
                yr.push(arr[0]);
            });
            vm.options = {
                chart: {
                    type: 'lineChart',
                    height: 450,
                    margin: {
                        top: 20,
                        right: 20,
                        bottom: 40,
                        left: 80
                    },
                    x: function (d) { return d.x; },
                    y: function (d) { return d.y; },
                    xAxis: {
                        axisLabel: $filter('translate')('global.table.' + vm.selected),
                        showMaxMin: false,
                        tickFormat: function (d) {
                            if (vm.selected === 'Quarter') {
                                return yAxis[d] + ' ' + yr[d];
                            }
                            return vm.monthArray[d] + ' ' + vm.selectedYears[d];
                        }
                    },
                    yAxis: {
                        axisLabel: $filter('translate')('global.table.quantity'),
                        axisLabelDistance: -20,
                        tickFormat: function (d) {
                            return d3.format('.2f')(d);
                        }
                    }
                },
                title: {
                    enable: true,
                    text: $filter('translate')('global.navbar.demandForecast')
                }
            };
            vm.dataYears1 = Object.keys(vm.purchaseTrendItems);
            vm.yeardata1 = vm.purchaseTrendItems[vm.dataYears1.slice(-1)[0]];
            vm.data = generateData(vm.outlets.length);

            /*Data Generator */
            function generateData(n) {
                return d3.range(n).map(function (index) {
                    var val1 = [];
                    var val2 = [];
                    var val3 = [];
                    var val4 = [];
                    var val = [];
                    vm.tempI = 1;
                    if (vm.selected === 'Quarter') {
                        for (var k = 0; k < yAxis.length; k++) {
                            val.push({ x: k, y: vm.quarterVal[0][vm.outlets[index]][temp[k] - 1] });
                        }
                    } else {
                        if (vm.filter.buyerBusinessIdList.length == 1) {
                            for (var i = 0; i < vm.monthArray.length; i++) {
                                val1.push({ x: i, y: vm.purchaseTrendItems[vm.years[index]][vm.outlets[index]][vm.monthArray[i]] });
                                if (vm.monthArray[i] == 'December') {
                                    val3 = val1;
                                    vm.tempI += i;
                                    break;
                                }
                            }

                            val3 = val3.filter(function (element) {
                                return element.y !== undefined;
                            });
                            _.map(vm.yeardata1[vm.outlets[index]], function (outletData, key) {
                                if (vm.yeardata1[vm.outlets[index]][vm.monthArray[vm.tempI]] != GLOBAL_CONSTANTS.MONTHS[limitMonth]) {
                                    val2.push({ x: vm.tempI, y: vm.yeardata1[vm.outlets[index]][vm.monthArray[vm.tempI]] });
                                    vm.tempI++;
                                }
                            })
                            val2 = val2.filter(function (element) {
                                return !!element.y;
                            });
                            val2 = val2.filter(function (element) {
                                return element.y !== undefined;
                            });
                            val = val3.concat(val2);

                        }
                        else {
                            for (var i = 0; i < vm.monthArray.length; i++) {
                                val1.push({ x: i, y: vm.purchaseTrendItems[vm.years[index]][vm.outlets[index]][vm.monthArray[i]] });
                                if (vm.monthArray[i] == 'December') {
                                    val3 = val1;
                                    vm.tempI += i;
                                    break;
                                }
                            }
                            _.map(vm.yeardata1[vm.outlets[index]], function (outletData, key) {
                                if (vm.yeardata1[vm.outlets[index]][vm.monthArray[vm.tempI]] != GLOBAL_CONSTANTS.MONTHS[limitMonth]) {
                                    val2.push({ x: vm.tempI, y: vm.yeardata1[vm.outlets[index]][vm.monthArray[vm.tempI]] });
                                    vm.tempI++;
                                }
                            })
                            val3 = val3.filter(function (element) {
                                return element.y !== undefined;
                            });

                            val2 = val2.filter(function (element) {
                                return element.y !== undefined;
                            });
                            val = val3.concat(val2);

                        }
                    }
                    return {
                        values: val,
                        key: vm.outlets[index],
                    };
                });
            }
        }

        /**
         * [getYears Gets the Year in order]
         */
        function getYears() {
            vm.selectedYears = d3.range(vm.limit);
            // _.fill(vm.selectedYears,moment(vm.fromDate).year(),0, vm.limit-moment(vm.fromDate).month()+1);
            // _.fill(vm.selectedYears,moment(vm.toDate).year(),vm.limit-(moment(vm.fromDate).month()-1),vm.limit+1);
            var start = moment(vm.fromDate).year();
            var month = moment(vm.fromDate).month();
            vm.selectedYears = [];
            for (var i = 0; i < vm.limit; i++) {
                vm.selectedYears.push(start);
                month++;
                if (month == 12) {
                    start++;
                }
            }
            vm.years = [];
            for (var i = 0; i < vm.outlets.length; i++) {
                _.map(vm.selectedYears, function (year) {
                    vm.years.push(year);
                });
            }
        }

        /**
         * [sortByMonth - Sorts the array of month in chronological order]
         * @param {[type]} month
         */
        function sortByMonth(month) {
            var months = GLOBAL_CONSTANTS.MONTHS;
            month.sort(function (a, b) {
                return months.indexOf(a)
                    - months.indexOf(b);
            });
        }

        /**
         * [getQuarterSum -Calculates the qurter amount of the business outlet.]
         */
        function getQuarterSum() {
            vm.quarterSum = [];
            var amountArray = [];
            vm.groupedArray = _.chunk(vm.months, 3);
            _.map(vm.outlets, function (outlet, i) {
                for (var j = 0; j < vm.groupedArray.length; j++) {
                    amountArray = _.map(vm.groupedArray[j], function (month) { return vm.purchaseTrendItems[vm.selectedYears[i]][outlet][month]; });
                    var sum = _.reduce(amountArray, function (sum, n) {
                        return sum + n;
                    }, 0);
                    vm.quarterSum.push(sum);
                }
            });
        }

        /**
        * [loadAll loads all price update history]
        */
        function loadAll() {
            vm.toDateChangeAPI = vm.toDate;
            if (!vm.validDateFilter()) {
                return false;
            }
            if (vm.varience == undefined) {
                vm.varience = 0;
            }
            if (vm.selected == 'Month') {
                BusinessReportService.getDemandForecast({
                    fromDate: !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
                    toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
                    buyerBusinessIdList: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList,
                    supplierIdList: !vm.filter.supplierIdList ? [] : vm.filter.supplierIdList,
                    Listview: !vm.selected ? '' : vm.selected,
                    itemId: vm.itemId ? vm.itemId : '',
                    page: vm.query.page - 1,
                }).then(function (response) {
                    vm.apidata = response.data;
                    if (Object.keys(vm.apidata).length == 0) {
                        vm.purchaseTrendItems = null;
                        return false
                    }

                    vm.outletArray = Object.keys(vm.apidata);
                    vm.purchaseTrendItems = {};
                    vm.currentYear = moment(vm.fromDate).year();
                    vm.toYear = moment(vm.toDate).year();
                    vm.currentMonthIndex = moment(vm.fromDate).month();
                    vm.toMonthIndex = moment(vm.toDate).month();
                    vm.currentQuarterIndex = vm.getQuarterIndex(vm.currentMonthIndex);
                    vm.toQuarterIndex = vm.getQuarterIndex(vm.toMonthIndex);

                    if (vm.selected == 'Month') {
                        vm.forecastBudgetMonth();
                    } else {
                        vm.forecastBudgetQuarter();
                    }

                    vm.options = {
                        chart: {
                            type: 'lineChart',
                            height: 450,
                            margin: {
                                top: 20,
                                right: 20,
                                bottom: 40,
                                left: 80
                            },
                            x: function (d) { return d.x; },
                            y: function (d) { return d.y; },
                            xAxis: {
                                axisLabel: $filter('translate')('global.table.' + vm.selected),
                                showMaxMin: false,
                                tickFormat: function (d) {
                                    return vm.xAxis[d];
                                }
                            },
                            yAxis: {
                                axisLabel: $filter('translate')('global.table.value'),
                                axisLabelDistance: -20,
                                tickFormat: function (d) {
                                    return d3.format('.2f')(d);
                                }
                            }
                        },
                        title: {
                            enable: true,
                            text: $filter('translate')('global.navbar.demandForecast')
                        }
                    };

                    vm.xAxis = [];

                    vm.data = graphDataGenerator();

                }, function (error) {
                    NotificationService.error({
                        title: 'Fetching Demand Forecast',
                        error: error
                    });
                });
            }
            else {
                BusinessReportService.getDemandForecastQuarter({
                    fromDate: !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
                    toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
                    buyerBusinessIdList: !vm.filter.buyerBusinessIdList ? [] : vm.filter.buyerBusinessIdList,
                    supplierIdList: !vm.filter.supplierIdList ? [] : vm.filter.supplierIdList,
                    Listview: !vm.selected ? '' : vm.selected,
                    itemId: vm.itemId ? vm.itemId : '',
                    page: vm.query.page - 1,
                }).then(function (response) {
                    vm.apidata = response.data;
                    if (Object.keys(vm.apidata).length == 0) {
                        vm.purchaseTrendItems = null;
                        return false
                    }
                    vm.outletArray = Object.keys(vm.apidata);
                    vm.purchaseTrendItems = {};
                    vm.currentYear = moment(vm.fromDate).year();
                    vm.toYear = moment(vm.toDate).year();
                    vm.currentMonthIndex = moment(vm.fromDate).month();
                    vm.toMonthIndex = moment(vm.toDate).month();
                    vm.currentQuarterIndex = vm.getQuarterIndex(vm.currentMonthIndex);
                    vm.toQuarterIndex = vm.getQuarterIndex(vm.toMonthIndex);

                    if (vm.selected == 'Month') {
                        vm.forecastBudgetMonth();
                    } else {
                        vm.forecastBudgetQuarter();
                    }

                    vm.options = {
                        chart: {
                            type: 'lineChart',
                            height: 450,
                            margin: {
                                top: 20,
                                right: 20,
                                bottom: 40,
                                left: 80
                            },
                            x: function (d) { return d.x; },
                            y: function (d) { return d.y; },
                            xAxis: {
                                axisLabel: $filter('translate')('global.table.' + vm.selected),
                                showMaxMin: false,
                                tickFormat: function (d) {
                                    return vm.xAxis[d];
                                }
                            },
                            yAxis: {
                                axisLabel: $filter('translate')('global.table.value'),
                                axisLabelDistance: -20,
                                tickFormat: function (d) {
                                    return d3.format('.2f')(d);
                                }
                            }
                        },
                        title: {
                            enable: true,
                            text: $filter('translate')('global.navbar.demandForecast')
                        }
                    };

                    vm.xAxis = [];
                    vm.data = graphDataGenerator();

                }, function (error) {
                    NotificationService.error({
                        title: 'Fetching Demand Forecast',
                        error: error
                    });
                });
            }
        }

        function getQuarterIndex(monthIndex) {
            if (monthIndex < 3)
                return 0;
            else if (monthIndex < 6)
                return 1;
            else if (monthIndex < 9)
                return 2;
            else
                return 3;
        }

        function budgetForecast(n, n1, n2) {
            var result = 0;
            if (!n && !n1 && !n2) {
                result = 0;
            } else if (!n1 && !n2) {
                result = n;
            } else if (!n2) {
                result = (n + n * ((n - n1) / n1));
            } else if (!n && !n2) {
                result = (n + n * ((1 * ((n - n1) / n1) + ((n1 - n2) / n2)) / 2));
            }
            else {
                result = (n + n * ((1 * ((n - n1) / n1) + ((n1 - n2) / n2)) / 2));
            }
            result = result || 0;
            var p = (Math.round(result * 100) / 100);
            return p + (p * (vm.varience / 100));
        }



        function getPreviousMonthOrQuarter(year, current, max) {
            if (current <= 0) {
              return [year - 1, max];
            } else {
              return [year, current - 1];
            }
          }

          function setPreviousMonthData(outlet, year, month, pId, value) {
            if (!vm.purchaseTrendItems[outlet][year]) {
              vm.purchaseTrendItems[outlet][year] = [];
            }
            vm.purchaseTrendItems[outlet][year].push({
              id: pId,
              month: "End of "+month,
              value: value
            });
          }

          function setPreviousQtrData(outlet, year, qtr, pId, value) {
            if (!vm.purchaseTrendItems[outlet][year]) {
              vm.purchaseTrendItems[outlet][year] = [];
            }
            vm.purchaseTrendItems[outlet][year].push({
              id: pId,
              q: "End of "+qtr,
              value: value
            });
          }

        function forecastBudgetMonth() {
            _.each(vm.outletArray, function (outlet, index) {
                var algoData = vm.apidata[outlet];
                var pYear = vm.currentYear;
                var pMonthI = vm.currentMonthIndex;
                var pId = 1;
                vm.purchaseTrendItems[outlet] = {};
                var n1Month = getPreviousMonthOrQuarter(pYear, pMonthI, 11);
            var n2Month = getPreviousMonthOrQuarter(n1Month[0], n1Month[1], 11);
            var n3Month = getPreviousMonthOrQuarter(n2Month[0], n2Month[1], 11);
            setPreviousMonthData(outlet, n3Month[0], GLOBAL_CONSTANTS.MONTHS[n3Month[1]], pId, algoData.n3); pId += 1;
            setPreviousMonthData(outlet, n2Month[0], GLOBAL_CONSTANTS.MONTHS[n2Month[1]], pId, algoData.n2); pId += 1;
            setPreviousMonthData(outlet, n1Month[0], GLOBAL_CONSTANTS.MONTHS[n1Month[1]], pId, algoData.n1); pId += 1;
                while ((pYear == vm.toYear && pMonthI <= vm.toMonthIndex) || pYear < vm.toYear) {
                    if (!vm.purchaseTrendItems[outlet][pYear]) {
                        vm.purchaseTrendItems[outlet][pYear] = [];
                    }
                    var pred = vm.budgetForecast(algoData.n1, algoData.n2, algoData.n3);
                    vm.purchaseTrendItems[outlet][pYear].push({
                        id: pId,
                        month: "End of "+GLOBAL_CONSTANTS.MONTHS[pMonthI],
                        value: pred
                    });
                    // set next algorithm values
                    algoData.n3 = algoData.n2;
                    algoData.n2 = algoData.n1;
                    algoData.n1 = pred;
                    // incrementing counters
                    pId += 1;
                    pMonthI = (pMonthI + 1) % 12;
                    if (pMonthI == 0) {
                        pYear += 1;
                    }
                }
            });

        }

        function forecastBudgetQuarter() {
            _.each(vm.outletArray, function (outlet, index) {
                var algoData = vm.apidata[outlet];
                var pYear = vm.currentYear;
                var pQuarterI = vm.currentQuarterIndex;
                var pId = 1;
                vm.purchaseTrendItems[outlet] = {};
                var n1Qtr = getPreviousMonthOrQuarter(pYear, pQuarterI, 3);
            var n2Qtr = getPreviousMonthOrQuarter(n1Qtr[0], n1Qtr[1], 3);
            var n3Qtr = getPreviousMonthOrQuarter(n2Qtr[0], n2Qtr[1], 3);
            setPreviousQtrData(outlet, n3Qtr[0], GLOBAL_CONSTANTS.QUARTERS[n3Qtr[1]], pId, algoData.n3); pId += 1;
            setPreviousQtrData(outlet, n2Qtr[0], GLOBAL_CONSTANTS.QUARTERS[n2Qtr[1]], pId, algoData.n2); pId += 1;
            setPreviousQtrData(outlet, n1Qtr[0], GLOBAL_CONSTANTS.QUARTERS[n1Qtr[1]], pId, algoData.n1); pId += 1;
                while ((pYear == vm.toYear && pQuarterI <= vm.toQuarterIndex) || pYear < vm.toYear) {
                    if (!vm.purchaseTrendItems[outlet][pYear]) {
                        vm.purchaseTrendItems[outlet][pYear] = [];
                    }
                    var pred = vm.budgetForecast(algoData.n1, algoData.n2, algoData.n3);
                    vm.purchaseTrendItems[outlet][pYear].push({
                        id: pId,
                        q: "End of "+GLOBAL_CONSTANTS.QUARTERS[pQuarterI],
                        value: pred
                    });
                    // set next algorithm values
                    algoData.n3 = algoData.n2;
                    algoData.n2 = algoData.n1;
                    algoData.n1 = pred;
                    // incrementing counters
                    pId += 1;
                    pQuarterI = (pQuarterI + 1) % 4;
                    if (pQuarterI == 0) {
                        pYear += 1;
                    }
                }
            });
        }


        function graphDataGenerator() {
            var graphData = [];
            vm.outletArray.forEach(function (outlet) {
                var outletLine = {
                    values: coordinatesGenerator(vm.purchaseTrendItems[outlet]),
                    key: outlet
                };
                graphData.push(outletLine);
            });
            return graphData;
        }

        function coordinatesGenerator(outletData) {
            vm.xAxis = [];
            var years = Object.keys(outletData);
            var coordinates = [];
            var x = 0;
            years.forEach(function (year) {
                var monthsOrQuaters = outletData[year];
                monthsOrQuaters.forEach(function (data) {
                    if (vm.selected == 'Month') {
                        var coordinate = { x: x, y: data.value };
                        coordinates.push(coordinate);
                        vm.xAxis.push(data.month + " " + year);
                    }
                    else {
                        var coordinate = { x: x, y: data.value };
                        coordinates.push(coordinate);
                        vm.xAxis.push(data.q + " " + year);
                    }
                    ++x;
                });
            });
            return coordinates;
        }

        function toDateSetonChange(view) {
            var year = vm.todaysDate.getFullYear();
            var month = vm.todaysDate.getMonth();
            var day = vm.todaysDate.getDate();
            if (view == 'Month') {
                vm.toDatedefault = new Date(year, month + 3, day - 1);
                vm.toDate = vm.toDatedefault;
            }
            else {
                vm.toDatedefault = new Date(year, month + 9, day - 1);
                vm.toDate = vm.toDatedefault;
            }
        }

        function resetFilters() {
            vm.filter = {}
            vm.fromDate = vm.todaysDate;
            vm.toDate = vm.toDatedefault;
            vm.itemId = null;
            vm.purchaseTrendItems = null;
        }
    }

})();
