/**
* @Author: hareesh
* @Date:   09-Aug-2017 12:51 +05:30
* @Project: Ezyprocure
* @Filename: supplier.purchase-request.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 15:00 +05:30
*/



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('SupplierPurchaseRequestController', SupplierPurchaseRequestController);

  SupplierPurchaseRequestController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', 'NotificationService', '$stateParams', 'pagingParams', 'toastr', '$mdDialog', 'UTCDateService', 'PurchaseOrderService', 'ItemGroupService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'CSVExportService', 'EXPORT_CONSTANTS', 'GLOBAL_CONFIG_CONSTANTS', 'BuyerSupplierMappingService', 'BuyerBusinessService', 'SupplierDataService', 'UtilService','CacheMiddlewareService'];

  function SupplierPurchaseRequestController($scope, $rootScope, $q, $filter, $state, NotificationService, $stateParams, pagingParams, toastr, $mdDialog, UTCDateService, PurchaseOrderService, ItemGroupService, GLOBAL_CONSTANTS, paginationConstants, CSVExportService, EXPORT_CONSTANTS, GLOBAL_CONFIG_CONSTANTS, BuyerSupplierMappingService, BuyerBusinessService, SupplierDataService, UtilService,CacheMiddlewareService) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.loadItemGroups = loadItemGroups;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };

    vm.prStatuses = GLOBAL_CONSTANTS.PR_STATUSES;
    vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.prStatusClass = GLOBAL_CONFIG_CONSTANTS.PROCESS_ORDER_STATUS.LABEL_CLASS;
    vm.limitOptions = paginationConstants.limitOptionsWithAll;
    //CSV Header
    vm.purchaseOrderHeader = EXPORT_CONSTANTS.CSV.SupplierPurchaseOrderReport;
    //CSV Function Declaration
    vm.getPurchaseOrderCSVData = getPurchaseOrderCSVData;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.purchaseOrderDTO = {
      buyerIdList: [],
      businessIdList: []
    };
    vm.resetFilters = resetFilters;
    vm.validateDateRange = validateDateRange;
    vm.loadAllIfSpecificFiltersFilled = loadAllIfSpecificFiltersFilled;
    //Get all buyers mapped to the supplier
    function getAllSupplierMappedBuyers() {
      vm.purchaseOrderDTO.businessIdList = [];
      vm.bussinessOutlets = [];
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if (vm.buyers)
        return false;
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function (response) {
        vm.buyers = response.data;
      }, function (error) {
      });
    }

    //get all business outlets of the selected buyer
    function getAllBuyersBusinessOutlets() {
      if (!vm.purchaseOrderDTO.buyerIdList) {
        return false;
      }
      if (vm.purchaseOrderDTO.businessIdList > 0) {
        return false;
      }
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        groupBy: 'buyer'
      };
      BuyerBusinessService.getAllBuyersBusinessOutlets(vm.purchaseOrderDTO.buyerIdList, params).then(function (response) {
        vm.buyerBusinessOutlets = response.data;
        vm.bussinessOutlets = [];
        _.forEach(vm.buyerBusinessOutlets, function (buyer) {
          _.forEach(buyer.businessDTOList, function (outlets) {
            vm.bussinessOutlets.push(outlets);
          });
        });
      }, function (error) {
      });
    }

    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BUYERS':
          vm.purchaseOrderDTO.buyerIdList = _.map(vm.buyers, _.property('id'));
          break;
        case 'BUSINESSOUTLETS':
          vm.purchaseOrderDTO.businessIdList = _.map(vm.bussinessOutlets, _.property('businessId'));
          break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'BUYERS':
          vm.purchaseOrderDTO.buyerIdList = [];
          break;
        case 'BUSINESSOUTLETS':
          vm.purchaseOrderDTO.businessIdList = [];
          break;
      }
    }

    //Getting CSV DATA
    function getPurchaseOrderCSVData() {
      var deferred = $q.defer();
      var exportMetaData = {
        dateRange: vm.dateRange,
        businessOutlets: vm.getBusinessOutletsForCSV()
      };
      if (!vm.poItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getSupplierPurchaseOrderReportCSV(vm.poItems, exportMetaData);
    }
    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

     

    function loadItemGroups() {
      var endpoint = '/api/item-groups';
      var param={page: 0,size: paginationConstants.maxLimit,query: ''};
      if (vm.itemGroups)
          return false;
      vm.itemGroups = null;
      CacheMiddlewareService.getData(endpoint,ItemGroupService.getAllItemGroupsData,param).
          then(function (response) {
            vm.itemGroups = response;
          }, function (error) {
          console.error('Error fetching item Groups:', error);
          });
  }
    /**
    * [loadAll Get all Purchase Order Details]
    */
    function loadAll() {    
      var loadAllIfSpecificFiltersFilled = vm.loadAllIfSpecificFiltersFilled();
      if (loadAllIfSpecificFiltersFilled && vm.filter.listView === "Individual"){
        getIndividualPODetails();
      }
    }

    /**
    * [getIndividualPODetails  gets PO details for each PO Number]
    */
    function getIndividualPODetails() {
      PurchaseOrderService.getAllPurchaseRequests({
        prNumber: !vm.filter.prNumber ? '' : vm.filter.prNumber,
        purchaseRequestStatus: vm.filter.prStatus,
        invoiceStatus: '',
        invoiceNumber: '',
        businessOutletId: vm.purchaseOrderDTO.businessIdList.length != 0 ?   vm.purchaseOrderDTO.businessIdList : '',
        businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
        //If dates are selected, they are converted to UTC format
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        query: !vm.filter.itemName ? '' : vm.filter.itemName,
        supplierId: '',
        itemGroupId: !vm.filter.itemGroupId ? '' : vm.filter.itemGroupId,
        buyerIdList: vm.purchaseOrderDTO.buyerIdList.length == 0 ? '' : vm.purchaseOrderDTO.buyerIdList,
        debitNoteStatus: '',
        grnNumber: '',
        buyerName: '',
        creditNoteStatus: '',
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
      }).then(function (response) {
        vm.poItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      }, function (error) {
        NotificationService.error({
          title: 'Purchase Order Details',
          error: error
        });
      });
    }

    function resetFilters() {
        vm.filter = {};
        vm.filter.prStatus = vm.prStatuses[0];
        vm.filter.fromDate = vm.todaysDate;
        vm.filter.toDate = vm.todaysDate;
        vm.purchaseOrderDTO.buyerIdList = '';
        vm.purchaseOrderDTO.businessIdList = '';
        vm.filter.listView = vm.listViews[0];
        vm.poItems = [];
        vm.query = {
          limit: paginationConstants.itemsPerPage,
          page: 1
        };
    }

    function validateDateRange(numberOfDays, title, filterAvailable) {
    var dateSelected = UtilService.checkDateRangeExists(vm.filter.fromDate, vm.filter.toDate, filterAvailable);
    if (!dateSelected) {
      toastr.error('Please select valid date ranges!', title);
      return false;
    }
    var dateValid = UtilService.checkDateRangeValidity(vm.filter.fromDate, vm.filter.toDate, numberOfDays, title, dateSelected);
    if (dateSelected && !dateValid) {
      return false;
    }     
    return true;
  }

  function loadAllIfSpecificFiltersFilled() {
    var validationResult = false;
    var filtersToCheck1 = ['prNumber','itemName','itemGroupId'], 
        filtersToCheck2 = ['businessIdList','buyerIdList'];
    var filterAvailable1 = UtilService.areSpecificFiltersFilled(filtersToCheck1,vm.filter), 
        filterAvailable2 = UtilService.areSpecificFiltersFilled(filtersToCheck2,vm.purchaseOrderDTO);
    
    var numberOfDays = GLOBAL_CONSTANTS.REPORTS_DATE_VALIDATION;
    var title = "Purchase Request"; 
    if(filterAvailable1 || filterAvailable2){
      validationResult = validateDateRange(numberOfDays, title, true);
    }else{
      validationResult = validateDateRange(numberOfDays, title, false);
    }
    return validationResult;
  }

  }
})();
