/*
 * @Author: Gayathri
 * @Date: 2018-12-26 11:46:35
 * @Project: Ezyprocure
 * @Filename: buyer.order-template.add.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-02-27T17:23:10+05:30
 */

(function () {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('OrderTemplateCreateController', OrderTemplateCreateController);

  OrderTemplateCreateController.$inject = ['$scope', 'ManageSupplierService', '$state','pagingParams','Auth','BuyerOrderTemplateService', 'BuyerBusinessUserMapService', 'BuyerBuyNowService', 'PurchaseOrderTypeService', 'NotificationService', 'paginationConstants', '$filter','$mdDialog','toastr','CacheMiddlewareService'];
  function OrderTemplateCreateController($scope, ManageSupplierService, $state, pagingParams, Auth, BuyerOrderTemplateService, BuyerBusinessUserMapService, BuyerBuyNowService, PurchaseOrderTypeService, NotificationService, paginationConstants, $filter,$mdDialog,toastr,CacheMiddlewareService) {
    var vm = this;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getAllSuppliersByBusinessOutlets = getAllSuppliersByBusinessOutlets;
    vm.loadPurchaseOrderTypes = loadPurchaseOrderTypes;
    vm.itemListLoaded = false;
    vm.getAllItems = getAllItems;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.getSelectedItemList = getSelectedItemList;
    vm.selectAllItems = selectAllItems;
    vm.deselectAll = deselectAll;
    vm.cancel=cancel;
    vm.showCreateTemplateModal=showCreateTemplateModal;
    vm.haveAccessForGLModule = Auth.hasPermission('accessForGlModule');

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }
    vm.checkAll = checkAll;
    vm.saveTemplate = saveTemplate;
    vm.showMore=showMore;
    vm.itemListQuery = {};

    //Get all business outlets of buyer
    function getAllBuyerBusinessOutlets() {
      var endpoint = '/api/business-outlets';
      if(vm.businessOutlets){
        return false;
      }
      CacheMiddlewareService.getData(endpoint,BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser).
      then(function (response) {
        vm.businessOutlets = response;
        //if only one business outlet set it as default
        if (vm.businessOutlets.length === 1) {
          vm.itemListQuery.businessIdList = vm.businessOutlets[0].id;
        }
      }, function (error) {
          console.error('Error fetching business outlets:', error);
      });
    }

    //Get all allocated suppliers of buyer business
    function getAllSuppliersByBusinessOutlets(businessIdList) {
      vm.supplierList=[];
      ManageSupplierService.getAllSuppliersByBusinessOutlets(businessIdList).then(function (response) {
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
        //if only one allocated supplier set it as default
        if(vm.supplierList.length === 1) {
          vm.itemListQuery.supplierId = vm.supplierList[0].id;
        }
      }, function (error) {
      });
    }

    //get all items
    function getAllItems() {
      vm.selectAll=false;
      if (vm.query.page === 1){
        vm.items = [];
      }
      var data={
        "buyerBusinessIdList": vm.itemListQuery.businessIdList,
        "supplierIdList": vm.itemListQuery.supplierId,
        "purchaseOrderTypeId": vm.itemListQuery.purchaseOrderTypeId ? vm.itemListQuery.purchaseOrderTypeId: null
      };
      var params = {
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      };
      BuyerOrderTemplateService.getAllItems(data,params).then(function (response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.itemListLoaded = true;
        vm.items = response.data;
      }, function (error) {
      });
    }

    //get all purchase order types
    function loadPurchaseOrderTypes() {
      if(vm.purchaseOrderTypes){
        return false;
      }
      PurchaseOrderTypeService.getAllPurchaseOrderTypes({isPageable: false}).then(function (response) {
        vm.purchaseOrderTypes = response.data;
      }, function (error) {
      });
    }

    /**
    * [get all selected items for the template]
    */
    function getSelectedItemList() {
      var itemIds = [];
      var selectedItems = _.filter(vm.items, {
        'selected': true
      });
      itemIds = _.map(selectedItems, function (item) {
        return {
          'id': item.id
        }
      })
      vm.selectedItemList = {
        "items": itemIds,
        "businessIdList": vm.itemListQuery.businessIdList,
        "name": vm.template.name,
        "purchaseOrderType": vm.itemListQuery.purchaseOrderTypeId ? {"id": vm.itemListQuery.purchaseOrderTypeId} : null
      }
      return vm.selectedItemList;
    }

    /**
    * [show the modal for create template]
    */
    function showCreateTemplateModal() {
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/order-template/modal-template/buyer.order-template.add-name.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false,
        reload:true
      });
    }

    function showMore() {
      vm.query.page += 1;
      vm.getAllItems();
    }


    /**
    * [selectAllItems select all from the list]
    */
    function selectAllItems(dropdown) {
      switch(dropdown){
        case 'SUPPLIERS':
          vm.itemListQuery.supplierId = _.map(vm.supplierList, _.property('id'));
          break;
        case 'BUSINESS_OUTLET':
          vm.itemListQuery.businessIdList =_.map(vm.businessOutlets, _.property('id'));
          break;
      }
    }

    /**
    * [deselectAll Deselect all from the list]
    */
    function deselectAll(dropdown) {
      switch(dropdown){
        case 'SUPPLIERS':
          vm.itemListQuery.supplierId = [];
          break;
        case 'BUSINESS_OUTLET':
        vm.itemListQuery.businessIdList = [];
          break;
      }
    }

    /**
    * [check all from items the list]
    */
    function checkAll() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      angular.forEach(vm.items, function (item) {
        item.selected = vm.selectAll;
      });
    }

    /**
    * [saveTemplate save template]
    */
    function saveTemplate() {
      vm.getSelectedItemList();
      if(vm.selectedItemList.items.length>0){
      BuyerOrderTemplateService.createOrderTemplate(vm.selectedItemList).then(function (response) {
        NotificationService.success({
          title: 'global.menu.entities.orderTemplate',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        $state.go('buyer.tools.order-template', { reload: false });
      }, function (error) {
        NotificationService.error({
          title: 'global.menu.entities.orderTemplate',
          error: error
        });
      });
    }
    else{
      NotificationService.simpleErrorToast({
        title: 'global.menu.entities.orderTemplate',
        message: 'global.pleaseSelectedOneFromTheList'
      });
    }
    }

    /**
    * [cancel the modal for create template]
    */
    function cancel(){
      $mdDialog.cancel();
      $state.go('buyer.tools.order-template', {
        reload: false
      });
    }
  }
}
)();
