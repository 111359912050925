/**
* @Author: hareesh
* @Date:   10-Aug-2017 05:54 +05:30
* @Project: Ezyprocure
* @Filename: buyer.costing.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 12-Oct-2018 14:52 +05:30
*/



(function() {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('BuyerCostingController', BuyerCostingController);

  BuyerCostingController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$state', '$stateParams', 'NotificationService', '$mdDialog', 'pagingParams', 'BuyerDataService', 'UTCDateService','BusinessReportService', 'BuyerSupplierMappingService', 'BuyerBusinessService',  'paginationConstants', 'BuyerBuyNowService', 'CSVExportService', 'EXPORT_CONSTANTS', 'BuyerBusinessUserMapService', '$timeout', 'MainItemGroupService', 'ItemGroupService', 'ReportService', "GLOBAL_CONSTANTS",'CacheMiddlewareService'];

  function BuyerCostingController($scope, $rootScope, $q, $filter, $state, $stateParams, NotificationService, $mdDialog, pagingParams, BuyerDataService, UTCDateService, BusinessReportService, BuyerSupplierMappingService, BuyerBusinessService,  paginationConstants, BuyerBuyNowService, CSVExportService, EXPORT_CONSTANTS, BuyerBusinessUserMapService, $timeout, MainItemGroupService, ItemGroupService, ReportService, GLOBAL_CONSTANTS,CacheMiddlewareService) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.todaysDate = new Date();
    vm.loadAll = loadAll;
    vm.supplier = '';
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.barChart=barChart;
    vm.getCostingCSVData = getCostingCSVData;
    vm.getCostingCSVHeader = EXPORT_CONSTANTS.CSV.Costing;
    vm.getCostingCSVHeader1 = EXPORT_CONSTANTS.CSV.CostingGroups;
    vm.getCostingCSVHeader2 = EXPORT_CONSTANTS.CSV.CostingSubGroups;
    vm.getAllSuppliers = getAllSuppliers;
    vm.loadMainItemGroups = loadMainItemGroups;
    vm.getAllGroupedItemGroups = getAllGroupedItemGroups;
    vm.mainItemGroupSelectionChanged = mainItemGroupSelectionChanged;
    vm.itemGroupSelectionChanged = itemGroupSelectionChanged;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;
    vm.filter = {
      businessOutletIdList: [],
      mainItemGroupIdList: [],
      itemGroupIdList: [],
      supplierIdList: []
    };
    vm.costingReportViewTypes = GLOBAL_CONSTANTS.COSTING_REPORT_VIEW_TYPES;
    vm.selected = "ITEM_NAME";
    vm.getBusinessOutletsNames = getBusinessOutletsNames;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.resetFilters = resetFilters;
    /**
    * [loadMainItemGroups Gets all Main Item Groups]
    */
    function loadMainItemGroups() {
      if (vm.mainItemGroups){
        return false;
      }
      MainItemGroupService.getAllMainItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.mainItemGroups = response.data;
      }, function(error) {
      });
    }

    /**
    * [getAllGroupedItemGroups Gets all ItemGroups Grouped by Main Item Groups]
    */
    function getAllGroupedItemGroups(mainItemGroupIdList) {
      if (vm.itemGroups && !vm.filter.mainItemGroupChanged){
        return false;
      }
      vm.filter.mainItemGroupChanged = false;
      ItemGroupService.getAllGroupedItemGroups(mainItemGroupIdList, {
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }
    /**
    * [getAllSuppliers Get all suppliers which satisfy the following]
    * 1. should be mapped with current logged in buyer
    * 2. who are selling items of selected main item groups and item groups
    */
    function getAllSuppliers() {
      if (vm.supplierList && !vm.filter.itemGroupChanged) {
        return false;
      }
      vm.filter.itemGroupChanged = false;
      ReportService.getAllSuppliers({
        mainItemGroupIdList: vm.filter.mainItemGroupIdList,
        itemGroupIdList: vm.filter.itemGroupIdList
      }, {
        page: 0,
        size: paginationConstants.maxLimit
      }).then(function(response) {
        vm.supplierList = response.data;
      }, function(error) {
      });
    }

    /**
    * [Resetting the filter when main item group selection changed ]
    */
    function mainItemGroupSelectionChanged() {
      vm.filter.mainItemGroupChanged = true;
      vm.filter.itemGroupIdList = [];
      vm.filter.supplierIdList = [];
    }

    /**
    * [Resetting the filter when sub item group selection changed ]
    */
    function itemGroupSelectionChanged() {
      vm.filter.itemGroupChanged = true;
      vm.filter.supplierIdList = [];
    }
    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups or itemGroups]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.businessOutletIdList = _.map(vm.businessOutlets, _.property('id'));
        break;
      case 'SUPPLIERS':
        vm.filter.supplierIdList = _.map(vm.supplierList, _.property('id'));
        break;
      case 'MAIN_ITEMGROUPS':
        vm.mainItemGroupSelectionChanged();
        vm.filter.mainItemGroupIdList = _.map(vm.mainItemGroups, _.property('id'));
        break;
      case 'ITEMGROUPS':
        vm.itemGroupSelectionChanged();
        vm.filter.itemGroupIdList = _.map(_.flatten(_.without(_.map(vm.itemGroups, _.property('itemGroupDTOList')), undefined, null)), _.property('itemGroupId'));
        break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, mainItemGroups or itemGroups]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
      case 'OUTLETS':
        vm.filter.businessOutletIdList = [];
        break;
      case 'SUPPLIERS':
        vm.filter.supplierIdList = [];
        break;
      case 'MAIN_ITEMGROUPS':
        vm.filter.mainItemGroupIdList = [];
        vm.mainItemGroupSelectionChanged();
        break;
      case 'ITEMGROUPS':
        vm.filter.itemGroupIdList = [];
        vm.itemGroupSelectionChanged();
        break;
      }
    }

    /**
    [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
    function getAllBuyerBusinessOutlets() {
      var endpoint = '/api/business-outlets';
      if(vm.businessOutlets)
          return false;
      vm.businessOutlets = null;
      CacheMiddlewareService.getData(endpoint,BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser).then(function(response){
          vm.businessOutlets = response;
          //if only one business outlet set it as default
          if (vm.businessOutlets.length === 1) {
              vm.businessId = vm.businessOutlets[0].id;
          }
      }, function(error){
          console.error('Error fetching business outlets:', error);
      });
  }


    //Getting CSV DATA
    function getCostingCSVData(){
      var deferred = $q.defer();
      var exportMetaData = {
        dateRange: vm.fromDate.getDate() +'/' + ( vm.fromDate.getMonth() + 1 ) +'/' + vm.fromDate.getFullYear() + '-' +vm.toDate.getDate() +'/' + ( vm.toDate.getMonth() + 1 ) +'/' + vm.toDate.getFullYear(),
        businessOutlets: vm.outletnames
      };
      if (!vm.priceUpdateItems) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.reject;
      }else {
        return CSVExportService.getCostingCSV(vm.priceUpdateItems, exportMetaData, vm.selected);
      }
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [Draws the bar chart]
    */
    function barChart(){
      var count = 0;
      var data = [];
      var data_values = [];
      var outlet_data = [];
      vm.data = [
        {
          "key" : "Quantity" ,
          "bar": true,
          "values" : null
        }];
      _.map(vm.priceUpdateItems,function(priceUpdateItem){
        vm.selected === 'ITEM_NAME' ? data_values.push(priceUpdateItem.itemName + ' [' + priceUpdateItem.businessOutlets[0] + ']'): (vm.selected === 'SUB_ITEM_GROUP' ? data_values.push(priceUpdateItem.itemGroup): data_values.push(priceUpdateItem.mainItemGroup));
        data.push([count, priceUpdateItem.amount]);
        vm.selected === 'ITEM_NAME' ? outlet_data.push(priceUpdateItem.businessOutlets[0]) : outlet_data.push(count);
        count += 1;
      });
      vm.data[0].values = data;
      vm.options = {
        chart: {
          type: 'historicalBarChart',
          height: 600,
          margin : {
            top: 20,
            right: 20,
            bottom: 150,
            left: 50
          },
          x: function(d){return d[0];},
          y: function(d){return d[1];},
          showValues: true,
          duration: 100,
          xAxis: {
            axisLabel: '',
            tickFormat: function(d) {
              return data_values[d];
            },
            rotateLabels: 20,
            showMaxMin: false
          },
          yAxis: {
            axisLabel: 'Amount',
            axisLabelDistance: -10,
            tickFormat: function(d){
              return d3.format('.2s')(d);
            }
          },
          tooltip: {
            keyFormatter: function(d) {
              return outlet_data[d];
            }
          },
          zoom: {
            enabled: true,
            scaleExtent: [1, 10],
            useFixedDomain: false,
            useNiceScale: false,
            horizontalOff: false,
            verticalOff: true,
            unzoomEventType: 'dblclick.zoom'
          }
        }
      };
    }

    /**
    * [loadAll loads all costing reports]
    */
    function loadAll() {
      BusinessReportService.getCosting({
        fromDate: !vm.fromDate ? '' : UTCDateService.utcFromDate(vm.fromDate),
        toDate: !vm.toDate ? '' : UTCDateService.utcToDate(vm.toDate),
        buyerBusinessIdList: !vm.filter.businessOutletIdList ? [] : vm.filter.businessOutletIdList,
        supplierIdList: !vm.filter.supplierIdList ? [] : vm.filter.supplierIdList,
        itemGroupIdList: !vm.filter.itemGroupIdList ? [] : vm.filter.itemGroupIdList,
        mainItemGroupIdList:  !vm.filter.mainItemGroupIdList ? [] : vm.filter.mainItemGroupIdList,
        query: !vm.filter.itemName ? '' : vm.filter.itemName,
        costingReportViewType:vm.selected
      },{
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      }).then(function(response) {
        vm.priceUpdateItems = response.data;
        vm.date_dis="";
        vm.date_dis = vm.fromDate.getDate() +'/' + ( vm.fromDate.getMonth() + 1 ) +'/' + vm.fromDate.getFullYear() + '-' +vm.toDate.getDate() +'/' + ( vm.toDate.getMonth() + 1 ) +'/' + vm.toDate.getFullYear();
        vm.totalItems = response.headers('X-Total-Count');
        vm.barChart=barChart();
      }, function(error) {
      });
    }

    /**
    * [Returns all business outlet names seperated by commas]
    */
    function getBusinessOutletsNames(outletNames){
      return _.join(outletNames, ',');
    }

    function resetFilters() {
        vm.filter = {}
        vm.fromDate = vm.todaysDate;
        vm.toDate = vm.todaysDate;
        vm.priceUpdateItems = null;
    }


  }
})();
