/*
 * @Author: Gayathri
 * @Date: 2018-12-26 11:46:35
 * @Project: Ezyprocure
 * @Filename: buyer.order-template.edit.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-02-28T09:13:18+05:30
 */
(function () {
  'use strict';
  angular
  .module('ezyprocureApp')
  .controller('OrderTemplateEditController', OrderTemplateEditController);
  OrderTemplateEditController.$inject = ['$state', '$stateParams', 'ManageSupplierService', 'NotificationService', 'Auth', 'BuyerOrderTemplateService', 'BuyerBusinessUserMapService', 'paginationConstants', '$mdDialog','pagingParams','$filter','toastr','CacheMiddlewareService'];
  function OrderTemplateEditController($state, $stateParams, ManageSupplierService, NotificationService, Auth, BuyerOrderTemplateService, BuyerBusinessUserMapService, paginationConstants, $mdDialog, pagingParams,$filter,toastr,CacheMiddlewareService) {

    var vm = this;
    vm.templateId = parseInt($stateParams.templateId);
    vm.getOrderTemplateById = getOrderTemplateById;
    vm.editOrderTemplate = editOrderTemplate;
    vm.getAllItems = getAllItems;
    vm.init = init;
    vm.checkAll = checkAll;
    vm.getSupplierList = getSupplierList;
    vm.getSelectedItemList = getSelectedItemList;
    vm.itemListLoaded = false;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.selected = selected;
    vm.showMore=showMore;
    vm.haveAccessForGLModule = Auth.hasPermission('accessForGlModule');
    vm.deselectAll = deselectAll;
    vm.selectAllItems = selectAllItems;
    vm.getAllSuppliersByBusinessOutlets = getAllSuppliersByBusinessOutlets;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function init() {
      vm.getOrderTemplateById();
    }

    //get order template by template id
    function getOrderTemplateById() {
      BuyerOrderTemplateService.getOrderTemplateById(vm.templateId).then(function (response) {
        vm.template = response;
        vm.getSupplierList();
        vm.getAllBuyerBusinessOutlets();
        vm.businessIdList = vm.template.orderTemplate.buyerBusiness != null ? [vm.template.orderTemplate.buyerBusiness.id] : vm.template.buyerBusinesses.map(function(b){return b.id});
        vm.getAllSuppliersByBusinessOutlets(vm.businessIdList);
        vm.getAllItems();
      });
    }

    //Get all business outlets of buyer
    function getAllBuyerBusinessOutlets() {
      var endpoint = '/api/business-outlets';
      if(vm.businessOutlets)
          return false;
      vm.businessOutlets = null;
      CacheMiddlewareService.getData(endpoint,BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser).then(function(response){
          vm.businessOutlets = response;
           
      }, function(error){
          console.error('Error fetching business outlets:', error);
      });
  }

    //get the supplier list for the items in the template
    function getSupplierList() {
      vm.supplierIdList = _.map(vm.template.items, _.property('supplier.id'));
      return vm.supplierIdList;
    }

    //get all items
    function getAllItems() {
      if (vm.query.page === 1){
        vm.items = [];
      }
      var params = {
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      };
      BuyerOrderTemplateService.getAllItems({
        "buyerBusinessIdList": vm.businessIdList,
        "supplierIdList": vm.supplierIdList,
        "purchaseOrderTypeId":vm.template.orderTemplate.purchaseOrderType ? (vm.template.orderTemplate.purchaseOrderType.id ? vm.template.orderTemplate.purchaseOrderType.id: null) : null
      },params).then(function (response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.items = response.data;
        vm.selected();
        vm.itemListLoaded = true;
      }, function (error) {
     });
    }

   /**
    * [items to be selected by default]
    */
  function selected() {
    _.filter(vm.items, function (item) {
      for (var i = 0; i < vm.template.items.length; i++) {
        if (vm.template.items[i].id == item.id) {
          return item.selected = true;
        }
      }
    })
  }

   /**
    * [selectAllItems select all from the list]
    */
   function selectAllItems(dropdown) {
    switch(dropdown){
      case 'SUPPLIERS':
        vm.supplierIdList = _.map(vm.supplierList, _.property('id'));
        break;
      case 'OUTLETS':
        vm.businessIdList = _.map(vm.businessOutlets, _.property('id'));
        break;
    }
  }

  /**
  * [deselectAll Deselect all from the list]
  */
  function deselectAll(dropdown) {
    switch(dropdown){
      case 'SUPPLIERS':
        vm.supplierIdList = [];
        break;
      case 'OUTLETS':
        vm.businessIdList = [];
        break;
    }
  }

  /**
   * [getAllSuppliersByBusinessOutlets Get all allocated suppliers of buyer business]
   */
  function getAllSuppliersByBusinessOutlets(businessIdList) {
    vm.supplierList=[];
    ManageSupplierService.getAllSuppliersByBusinessOutlets(businessIdList).then(function (response) {
      vm.supplierList = $filter('orderBy')(response.data, 'companyName');
      var supplierIdList = _.uniq(_.map(vm.supplierList, 'id'));
      var templateSuppliers = vm.getSupplierList();
      vm.supplierIdList = _.intersection(supplierIdList,templateSuppliers);
    }, function (error) {
    });
  }

  //select all items
  function checkAll() {
    vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
    angular.forEach(vm.items, function (item) {
      item.selected = vm.selectAll;
    });
  }

  /**
    * [get all selected items for the template]
    */
  function getSelectedItemList() {
    var itemIds = [];
    var selectedItems = _.filter(vm.items, {
      'selected': true
    });
    itemIds = _.map(selectedItems, function (item) {
      return {
        'id': item.id
      }
    });
    var outletIds = _.map(vm.businessIdList, function(b) {
      return {
        'id': b
      };
    });
    vm.selectedItemList = {
      "items": itemIds,
      "orderTemplate": {
        "buyerBusiness": vm.template.orderTemplate.buyerBusiness != null ? { "id": vm.template.orderTemplate.buyerBusiness.id } : null,
        "id": vm.templateId,
        "name": vm.template.orderTemplate.name,
        "purchaseOrderType": vm.template.orderTemplate.purchaseOrderType ? (vm.template.orderTemplate.purchaseOrderType.id ? { "id": vm.template.orderTemplate.purchaseOrderType.id } : null) : null
      },
      "buyerBusinesses": vm.template.orderTemplate.buyerBusiness == null ? outletIds : null
    }
    return vm.selectedItemList;
  }

  //edit order template
  function editOrderTemplate() {
    vm.getSelectedItemList();
    if (vm.selectedItemList.items.length > 0) {
      BuyerOrderTemplateService.editOrderTemplate(vm.selectedItemList).then(function (response) {
        NotificationService.success({
          title: 'global.menu.entities.orderTemplate',
          message: 'Changed successfully'
        });
        $state.go('buyer.tools.order-template', { reload: false });
      }, function (error) {
        NotificationService.error({
          title: 'global.menu.entities.orderTemplate',
          error: error
        });
      });
    } else {
      NotificationService.simpleErrorToast({
        title: 'global.menu.entities.orderTemplate',
        message: 'global.pleaseSelectedOneFromTheList'
      });
    }
  }

  function showMore() {
    vm.query.page += 1;
    vm.getAllItems();
  }
}
})();
