/**
* @Author: Anju
* @Date:   23-May-2024 11:40 +05:30
* @Project: Ezyprocure
* @Filename: admin.subscription-management-reports.controller.js
*/

(function () {
    'use strict';

    angular
        .module('ezyprocureApp')
        .controller('SubscriptionManagementReportsController', SubscriptionManagementReportsController);

    SubscriptionManagementReportsController.$inject = ['$scope', 'pagingParams', 'NotificationService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'CSVExportService', '$stateParams', 'Auth', '$mdDialog', 'BuyerBusinessUserMapService', 'SubscriptionManagementReportService', 'S3_URL_CONSTANTS', 'CsvUploadService', 'UTCDateService', 'toastr', 'EXPORT_CONSTANTS','$q','CacheMiddlewareService'];

    function SubscriptionManagementReportsController($scope, pagingParams, NotificationService, GLOBAL_CONSTANTS, paginationConstants, CSVExportService, $stateParams, Auth, $mdDialog, BuyerBusinessUserMapService, SubscriptionManagementReportService, S3_URL_CONSTANTS, CsvUploadService, UTCDateService, toastr, EXPORT_CONSTANTS, $q,CacheMiddlewareService) {
        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
        vm.todaysDate = new Date();
        const currentYear = vm.todaysDate.getFullYear();
        vm.lastDateOfYear = new Date(currentYear, 11, 31);
        vm.isEditable = false;
        vm.loadAll = loadAll;
        vm.query = {
            limit: paginationConstants.itemsPerPage,
            page: 1
        };
        vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
        vm.limitOptions = paginationConstants.limitOptions;
        vm.filter = {};
        vm.userRole = Auth.getUserRole().ugly;
        vm.cancel = cancel;
        vm.editReport = editReport;
        vm.buyerAccountTypes = GLOBAL_CONSTANTS.BUYER_VISIBILITY_TYPES;
        vm.billingStatuses = GLOBAL_CONSTANTS.BILLING_STATUSES;
        vm.billingList = GLOBAL_CONSTANTS.BILLING_LIST;
        vm.outletTypes = GLOBAL_CONSTANTS.OUTLET_TYPES;
        vm.tabIndex = $stateParams.tabIndex ? $stateParams.tabIndex : 0;
        vm.exportSubscriptionReportExcel = exportSubscriptionReportExcel;
        vm.onBillableChange = onBillableChange;
        vm.selectAll = selectAll;
        vm.deselectAll = deselectAll;
        vm.onStatusChange = onStatusChange;
        vm.update = update;
        vm.tabClicked = tabClicked;
        $scope.dateLocale = {
            formatDate: function (date) {
                var m = moment(date);
                return m.isValid() ? m.format('DD/MM/YYYY') : '';
            }
        };
        vm.filter.subscriptionStartFromDate = null;
        vm.filter.subscriptionStartToDate = null;
        vm.filter.subscriptionEndFromDate = null;
        vm.filter.subscriptionEndToDate = null;
        vm.SUBSCRIPTION_MANAGEMENT_SUPPLIER_SAMPLE = S3_URL_CONSTANTS.SUBSCRIPTION_MANAGEMENT_SUPPLIER_SAMPLE;
        vm.SUBSCRIPTION_MANAGEMENT_BUYER_SAMPLE = S3_URL_CONSTANTS.SUBSCRIPTION_MANAGEMENT_BUYER_SAMPLE;
        vm.showExcelUpload = showExcelUpload;
        vm.getExcelBase64 = getExcelBase64;
        vm.resetValues = resetValues;
        vm.resetFilters = resetFilters;
        vm.getBuyerSubscriptionReportCSVData = getBuyerSubscriptionReportCSVData;
        vm.getSupplierSubscriptionReportCSVData = getSupplierSubscriptionReportCSVData;
        loadAll();
        /**
        * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
        */
        function getAllBuyerBusinessOutlets() {
            var endpoint = '/api/business-outlets';
            if (vm.businessOutlets)
                return false;
            vm.businessOutlets = null;
            CacheMiddlewareService.getData(endpoint,BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser).
                then(function (response) {
                    vm.businessOutlets = response;
                }, function (error) {
                console.error('Error fetching business outlets:', error);
                });
        }

        /**
        * [loadAll Load All subscription management report]
        * @param  {[string]} search [search text]
        */
        function loadAll(search) {
            if (!search) {
                vm.searchText = '';
            }
            
            var filter = {
                subscriptionStartFromDate: !vm.filter.subscriptionStartFromDate?"":UTCDateService.utcFromDate(vm.filter.subscriptionStartFromDate),
                subscriptionStartToDate: !vm.filter.subscriptionStartToDate?"":UTCDateService.utcToDate(vm.filter.subscriptionStartToDate),
                subscriptionEndFromDate: !vm.filter.subscriptionEndFromDate?"":UTCDateService.utcFromDate(vm.filter.subscriptionEndFromDate),
                subscriptionEndToDate: !vm.filter.subscriptionEndToDate?"":UTCDateService.utcToDate(vm.filter.subscriptionEndToDate),
                type: vm.filter.type,
                search: vm.searchText,
                page: vm.query.page - 1,
                size: vm.query.limit,
                sort: sort(),
            }
            if (vm.tabIndex === 0) {
                filter.type = "Buyer";
            } else {
                filter.type = "Supplier";
            }
            var validateSubscriptionDates = validateSubscriptionStartDates(filter.type);
            if(!validateSubscriptionDates)return;
            if(vm.filter.billingStatus==='BILLABLE'){
                filter.billingStatus = true;
            }
            if(vm.filter.billingStatus==='NON_BILLABLE'){
                filter.billingStatus = false;
            }
            if(vm.filter.outletType==='HQ'){
                filter.outletType = vm.filter.outletType;
            }
            if(vm.filter.outletType==='Sub-Outlet'){
                filter.outletType = vm.filter.outletType;
            }
            SubscriptionManagementReportService.getAllSubscriptionManagementReport(filter)
                .then(function (response) {
                    vm.totalItems = response.headers('X-Total-Count');
                    vm.reports = response.data;
                }, function (error) {
                    NotificationService.error({
                        title: 'Subscription Management Report',
                        error: error
                    });
                });
        }

        /**
        * [selectAllInvoices select all Pending Process Orders]
        */

        function editReport(id) {
            if(vm.tabIndex === 0){
                vm.reports.forEach(function (element) {
                    if (element.buyerBusinessId === id) {
                        vm.selectedReport = angular.copy(element);
                        vm.billableStr = vm.selectedReport.billable === true ? 'Billable' : 'NonBillable';
                        vm.status = vm.selectedReport.active === true ? 'Active' : 'Inactive';
                    }
                });
                $mdDialog.show({
                    templateUrl: 'app/admin-modules/subscription-management-report/admin.buyer.subscription-management.edit.html',
                    controller: function () {
                        return vm;
                    },
                    controllerAs: 'vm',
                    targetEvent: event,
                    clickOutsideToClose: true
                });
            }

            if(vm.tabIndex === 1){
                vm.reports.forEach(function (element) {
                    if (element.supplierId === id) {
                        vm.selectedReport = angular.copy(element);
                        vm.billableStr = vm.selectedReport.billable === true ? 'Billable' : 'NonBillable';
                        vm.status = vm.selectedReport.active === true ? 'Active' : 'Inactive';
                    }
                });
                $mdDialog.show({
                    templateUrl: 'app/admin-modules/subscription-management-report/admin.supplier.subscription-management.edit.html',
                    controller: function () {
                        return vm;
                    },
                    controllerAs: 'vm',
                    targetEvent: event,
                    clickOutsideToClose: true
                });
            }


        }

        /**
        * [sort to sort by PO date]
        */
        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            return result;
        }

        function exportSubscriptionReportExcel(type) {
            vm.subscriptionManagementCSVReport = CSVExportService.getSubscriptionManagementReportsCSVData(vm.reports, type);
            alasql('SELECT * INTO XLSX("Subscription_Report' + '.xlsx",{headers:true}) FROM ?', [vm.subscriptionManagementCSVReport]);
            var toastrDetails = {
                title: "global.navbar.subscriptionManagement",
                message: "alertMessage.GENERATED_SUCCESSFULLY"
            };
            NotificationService.success(toastrDetails);
        }

        //fetch buyer or supplier when selection changed
        function onBillableChange() {
            vm.selectedReport.billable = vm.billableStr === 'Billable' ? true : false;
        };

        //fetch buyer or supplier when selection changed
        function onStatusChange() {
            vm.selectedReport.status = vm.status === 'Active' ? true : false;
        };

        function selectAll(dropdown) {
            switch (dropdown) {
                case 'OUTLETS':
                    vm.filter.businessOutletId = _.map(vm.businessOutlets, _.property('id'));
                    break;
            }
        }

        function deselectAll(dropdown) {
            switch (dropdown) {
                case 'OUTLETS':
                    vm.filter.businessOutletId = [];
                    break;
            }
        }
        function cancel() {
            $mdDialog.cancel();
            vm.resetValues();
        }

        function update(user) {
            var data = {};
            if (vm.tabIndex===0 && user === "buyer"){
                data.buyerBusinessId = vm.selectedReport.buyerBusinessId;
                data.subscriptionAmount = vm.selectedReport.subscriptionAmount;
                data.billable = vm.selectedReport.billable;                
                data.subscriptionStartDate = vm.selectedReport.subscriptionStartDate;
                data.subscriptionEndDate = vm.selectedReport.subscriptionEndDate;                
                data.remarks = vm.selectedReport.remarks;
                data.status = vm.selectedReport.status;
                if(vm.selectedReport.billable === true){
                    data.billingType = vm.selectedReport.billingType;
                }
                SubscriptionManagementReportService.updateBuyerSubscriptionManagementReport(data)
                .then(function () {
                    NotificationService.success({
                        title: 'Subscription Management Report',
                        message: 'alertMessage.UPDATED_SUCCESSFULLY'
                    });
                    vm.cancel();
                    vm.loadAll();
                }, function (error) {
                    NotificationService.error({
                        title: 'Subscription Management Report',
                        error: error
                    });
                });
            };
            if (vm.tabIndex===1 && user === "supplier"){
                data.supplierId = vm.selectedReport.supplierId;
                data.subscriptionAmount = vm.selectedReport.subscriptionAmount;
                data.billable = vm.selectedReport.billable;
                data.subscriptionStartDate = vm.selectedReport.subscriptionStartDate;
                data.subscriptionEndDate = vm.selectedReport.subscriptionEndDate;                
                data.remarks = vm.selectedReport.remarks;
                if(vm.selectedReport.billable === true){
                    data.billingType = vm.selectedReport.billingType;
                }
                data.status = vm.selectedReport.status;
                SubscriptionManagementReportService.updateSupplierSubscriptionManagementReport(data)
                .then(function () {
                    NotificationService.success({
                        title: 'Subscription Management Report',
                        message: 'alertMessage.UPDATED_SUCCESSFULLY'
                    });
                    vm.cancel();
                    vm.loadAll();
                }, function (error) {
                    NotificationService.error({
                        title: 'Subscription Management Report',
                        error: error
                    });
                });
            };
        }

        function tabClicked(tabIndx){
            if(tabIndx===0){
              vm.tabIndex = 0;
            }
            if(tabIndx===1){
              vm.tabIndex = 1;
            }            
            vm.resetFilters();
        }

        function showExcelUpload(type) {
            $mdDialog.show({
                templateUrl: 'app/admin-modules/subscription-management-report/admin.subscription-management.excel-upload.html',
                controller: function () {
                    return vm;
                },
                controllerAs: 'vm',
                targetEvent: event,
                clickOutsideToClose: false
            });
        }

        /**
        * getExceBase64 gives the given excel in base64 encoded format
        */
        function getExcelBase64() {
            vm.recordError = [];
            vm.errorList = null;
            var csvFile = vm.files[0];
            CsvUploadService.getFileBase64(csvFile.lfFile, function (base64CSV) {
                vm.csvUploadData = base64CSV;
                addSubscriptionManagementReportViaCsvUpload(vm.csvUploadData);
            });
        }

        /**
         * addSubscriptionManagementReportViaCsvUpload upload a subscription management report list to be added
         * @param {uploadData}  
         */
        function addSubscriptionManagementReportViaCsvUpload(uploadData) {
            var type;
            if (vm.tabIndex === 0) {
                type = "BUYER";
            } else {
                type = "SUPPLIER";
            }
            SubscriptionManagementReportService.addSubscriptionManagementReportViaCsvUpload({ csvFile: uploadData , type: type})
                .then(function (response) {
                    vm.files = [];
                    vm.cancel();
                    vm.loadAll()
                    NotificationService.success({
                        title: 'global.navbar.subscriptionManagement',
                        message: 'alertMessage.CSV_UPLOAD_COMPLETED_SUCCESSFULLY'
                    });
                }, function (error) {
                    vm.recordError = error.data;
                    NotificationService.error({
                        title: 'alertMessage.ADD_SUBSCRIPTION_MANAGEMENT_REPORT_FAILED',
                        error: error
                    });
                });
        }

        function resetValues(){
            vm.recordError = [];
        }

        function resetFilters() {            
            vm.query = {
                limit: paginationConstants.itemsPerPage,
                page: 1
              };
            vm.totalItems = '';
            vm.recordError = [];
            vm.reports = [];
            vm.filter = {};
            vm.searchText = '';
            loadAll();
        }

        function validateSubscriptionStartDates(type) {
            if (vm.filter.subscriptionStartFromDate && !vm.filter.subscriptionStartToDate || (!vm.filter.subscriptionStartFromDate && vm.filter.subscriptionStartToDate)) {
                toastr.error('Both Subscription Start From Date and Subscription Start To Date must be selected!', type);
                return false;
            }
            if (vm.filter.subscriptionEndFromDate && !vm.filter.subscriptionEndToDate || (!vm.filter.subscriptionEndFromDate && vm.filter.subscriptionEndToDate)) {
                toastr.error('Both Subscription End From Date and Subscription End To Date must be selected!', type);
                return false;
            }
            return true;
        }
        
        //Export To Buyer Subscription Report CSV
        function getBuyerSubscriptionReportCSVData(type) {
            vm.buyerSubscriptionHeader = EXPORT_CONSTANTS.CSV.buyerSubscriptionReportListingHeader;
            var deferred = $q.defer();
            getCSVData(type).then(function (response) {
                if (_.isEmpty(response)) {
                    NotificationService.simpleErrorToast({
                        title: 'global.navbar.subscriptionManagement',
                        message: 'global.table.NO_RECORDS_FOUND'
                    });
                    return deferred.promise;
                } else {
                    var exportCSV = CSVExportService.getBuyerSubscriptionListingCSV(response);
                    deferred.resolve(exportCSV);
                }
            }, function (error) {
                NotificationService.simpleErrorToast({
                    title: 'global.navbar.subscriptionManagement',
                    message: error
                });
                deferred.reject(error);
            });
            return deferred.promise;
        }

        //Export To Supplier Subscription Report CSV
        function getSupplierSubscriptionReportCSVData(type) {
            vm.supplierSubscriptionHeader = EXPORT_CONSTANTS.CSV.supplierSubscriptionReportListingHeader;
            var deferred = $q.defer();
            getCSVData(type).then(function (response) {
                if (_.isEmpty(response)) {
                    NotificationService.simpleErrorToast({
                        title: 'global.navbar.subscriptionManagement',
                        message: 'global.table.NO_RECORDS_FOUND'
                    });
                    return deferred.promise;
                } else {
                    var exportCSV = CSVExportService.getSupplierSubscriptionListingCSV(response);
                    deferred.resolve(exportCSV);
                }
            }, function (error) {
                NotificationService.simpleErrorToast({
                    title: 'global.navbar.subscriptionManagement',
                    message: error
                });
                deferred.reject(error);
            });
            return deferred.promise;
        }

        function getCSVData(type) {
            var deferred = $q.defer();
            var filter = {};
            filter.type = type;
            filter.size = paginationConstants.maxLimit;
            SubscriptionManagementReportService.getAllSubscriptionManagementReport(filter)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function (error) {
                    NotificationService.error({
                        title: 'Subscription Management Report',
                        error: error
                    });
                    deferred.reject(error);
                });
            return deferred.promise;
        }

    }
})();
